@media (min-width: 1390px) and (max-width: 1600px) {
	.ds-main-card-body{gap: 15px;}
	.ds-main-left-icon span{height: 40px; width: 40px;}
	.ds-main-left-icon span svg{height: 25px; width: 25px;}
	.ds-main-right h3{font-size: 20px;}
	.ds-main-right span{font-size: 13px;}
	.ds-main-card.ds-page-main-card{padding: 14px;}
	.page-inner-title h2{font-size: 20px;}
	.wallet-address-card h3{font-size: 20px; font-weight: 400;}
	.affiliate-card .aff-card-head {gap: 10px; padding: 15px 10px;}
	.affiliate-card .aff-card-head h4{font-size: 15px; font-weight: 400;}
	.aff-card-body h3{font-size: 20px;}
	.aff-covert-holder-left h3{font-size: 13px;}
	.setting-tabs-left .nav-pills .nav-link{padding: 10px 8px; margin-bottom: 10px; }
	.tab-pill-left-detail{gap: 10px;}
	.tab-pill-left-detail h4{font-size: 15px;}
	.main-header-left{gap: 60px;}
	.main-menu{gap: 30px;}
	.main-menu-list-item{font-size: 15px;}
	.feature-card p{font-size: 16px; line-height: 21px;}
	.total-affiliate-benifits h4{font-size: 18px;}
	.total-affiliate-benifits h3{font-size: 80px;}
	.total-affiliate-benifits p{font-size: 20px;}
	.login-right{padding: 108px 50px 208px;}
	.copy-wallet-subscription input{font-size: 13px;}




}

@media (min-width: 1320px) and (max-width: 1380px) {
	.ds-main-card-body {gap: 15px; grid-gap: 15px;}
	.ds-main-card{padding: 13px;}
	.ds-main-left-icon span{height: 30px; width: 30px;}
	.ds-main-left-icon span svg{height: 18px; width: 18px;}
	.ds-main-right span{font-size: 13px; margin-bottom: 2px;}
	.ds-main-right h3{font-size: 18px;}
	.ds-main-card .ds-main-dropdown .dropdown-toggle svg {height: 11px; width: 12px;}
	.pricing-card-popular{padding: 25px 25px 18px;}
	.pricing-list-item p{font-size: 13px;}
	.pricing-list-item p{padding: 6px 0px;}
	.pricing-more-list span{padding: 4px 0px;}
	.affiliate-card .aff-card-head,
	.ds-main-card.affiliate-card,
	.aff-card-body{padding: 8px;}
	.affiliate-card .aff-card-head h4{font-size: 13px;}
	.aff-card-body h3{font-size: 18px;}
	.aff-card-body p{font-size: 11px;}
	.main-wrapper .container-fluid {width: 95%;}
	.setting-tabs-left .nav-pills .nav-link{padding: 15px 7px; margin-bottom: 8px;}
	.tab-pill-left-detail{gap: 10px;}
	.tab-pill-left-detail h4{font-size: 14px;}
	.subscription-inner-tabs .nav.nav-pills{gap: 10px;}
	.kyc-tab-detail h3{font-size: 12px;}
	.kyc-tabs-holder .subscription-inner-tabs .nav-pills .nav-item .nav-link{width: 210px;}
	.kyc-tabs-holder .subscription-inner-tabs .nav-pills .nav-item .nav-link span{font-size: 13px;}
	.kyc-tabs-holder .subscription-inner-tabs .nav-pills .nav-item .nav-link svg{font-size: 18px;}
	.help-card-body img {margin-bottom: 16px; width: 44px; height: 44px;}
	.help-card-body p{font-size: 15px;}	
	.help-card-body h3{font-size: 26px; margin-bottom: 4px;}
	.support-card {min-height: 150px;}
	.ds-card-title.support-title p {font-size: 14px; margin-bottom: 0;}
	.z-aside-holder .z-side-bar .z-drawer-menu{padding: 1.2rem 0 0px 0px;}
	.theme-table tbody tr td {padding: 7px 6px !important; font-size: 12px !important; font-weight: 300;}
	.theme-table .table thead tr th .table-head-th span{font-size: 12px;}
	.account-type-td ul li span{font-size: 11px; font-weight: 200;}
	.theme-table tbody tr td .btn.btn-sm.btn-dark{font-size: 12px !important; border-radius: 3px !important; padding: 6px 3px !important; width: 120px;}
	.main-header-left{gap: 40px;}
	.main-menu{gap: 35px;}
	.feature-card p{font-size: 16px;}
	.feature-card{padding: 20px;}
	.feature-card img{ margin-bottom: 20px; width: 60px;}
	.total-affiliate-benifits h4,
	.total-affiliate-benifits p{font-size: 20px;}
	.total-affiliate-benifits h3{font-size: 70px;}
	.aff-covert-holder-left h3 {font-size: 16px !important;}
	.aff-covert-holder-left p{font-size: 21px !important;}
	.start-how-list-holder{column-gap: 85px;}
	.start__item:before {content: ""; left: 166px; top: 80px; width: 10px;}
	.support-exchange-tbl-title h3{font-size: 19px;}
	.token-img-detail span {font-size: 12px; font-weight: 400;}
	.z-drawer-menu li a {padding: 14px 10px; font-size: 15px;}
	.token-img{gap: 7px;}
	.ds-card-title h3,
	.page-inner-title h2{font-size: 19px;}
	.table-pagination{margin-top: 20px;}
	.page-inner-title {margin-bottom: 20px;}
	.input-holder input{font-size: 14px;}
	.dropdown-header p {font-size: 14px;}
	.wallet-address-card .scan-wallet-img img {width: 180px;}
	.z-theme-wrapper .btn-lg {font-size: 15px; padding: 7px 27px;}

}


@media (min-width: 1200px) and (max-width: 1280px) {
	.ds-main-card-body {gap: 15px; grid-gap: 15px;}
	.ds-main-card{padding: 13px;}
	.ds-main-left-icon span{height: 30px; width: 30px;}
	.ds-main-left-icon span svg{height: 18px; width: 18px;}
	.ds-main-right span{font-size: 13px; margin-bottom: 2px;}
	.ds-main-right h3{font-size: 18px;}
	.ds-main-card .ds-main-dropdown .dropdown-toggle svg {height: 11px; width: 12px;}
	.ds-main-card.ds-page-main-card .ds-main-card-body{    flex-direction: column; align-items: flex-start;}
	.pricing-card-popular{padding: 25px 25px 18px;}
	.pricing-list-item p{font-size: 13px;}
	.pricing-list-item p{padding: 6px 0px;}
	.pricing-more-list span{padding: 4px 0px;}
	.affiliate-card .aff-card-head,
	.ds-main-card.affiliate-card,
	.aff-card-body{padding: 8px;}
	.affiliate-card .aff-card-head h4{font-size: 13px;}
	.aff-card-body h3{font-size: 18px;}
	.aff-card-body p{font-size: 11px;}
	.main-wrapper .container-fluid {width: 95%;}
	.setting-tabs-left .nav-pills .nav-link{padding: 15px 7px; margin-bottom: 8px;}
	.tab-pill-left-detail{gap: 10px;}
	.tab-pill-left-detail h4{font-size: 15px;}
	.subscription-inner-tabs .nav.nav-pills{gap: 10px;}
	.kyc-tab-detail h3{font-size: 12px;}
	.kyc-tabs-holder .subscription-inner-tabs .nav-pills .nav-item .nav-link{width: 210px;}
	.kyc-tabs-holder .subscription-inner-tabs .nav-pills .nav-item .nav-link span{font-size: 13px;}
	.kyc-tabs-holder .subscription-inner-tabs .nav-pills .nav-item .nav-link svg{font-size: 18px;}
	.help-card-body img {margin-bottom: 16px; width: 44px; height: 44px;}
	.help-card-body p{font-size: 15px;}	
	.help-card-body h3{font-size: 26px; margin-bottom: 4px;}
	.support-card {min-height: 150px;}
	.ds-card-title.support-title p {font-size: 14px; margin-bottom: 0;}
	.z-aside-holder .z-side-bar .z-drawer-menu{padding: 1.2rem 0 0px 0px;}
	.theme-table tbody tr td {padding: 7px 6px !important; font-size: 12px !important; font-weight: 300;}
	.theme-table .table thead tr th .table-head-th span{font-size: 12px;}
	.account-type-td ul li span{font-size: 11px; font-weight: 200;}
	.theme-table tbody tr td .btn.btn-sm.btn-dark{font-size: 12px !important; border-radius: 3px !important; padding: 6px 3px !important; width: 120px;}
	.main-header-left{gap: 40px;}
	.main-menu{gap: 35px;}
	.feature-card p{font-size: 16px;}
	.feature-card{padding: 20px;}
	.feature-card img{ margin-bottom: 20px; width: 60px;}
	.total-affiliate-benifits h4,
	.total-affiliate-benifits p{font-size: 20px;}
	.total-affiliate-benifits h3{font-size: 70px;}
	.aff-covert-holder-left h3 {font-size: 16px !important;}
	.aff-covert-holder-left p{font-size: 21px !important;}
	.start-how-list-holder{column-gap: 85px;}
	.start__item:before {content: ""; left: 166px; top: 80px; width: 10px;}
	.support-exchange-tbl-title h3{font-size: 19px;}
	.token-img-detail span {font-size: 12px; font-weight: 400;}
	.z-drawer-menu li a {padding: 14px 10px; font-size: 15px;}
	.token-img{gap: 7px;}
	.ds-card-title h3,
	.page-inner-title h2{font-size: 19px;}
	.table-pagination{margin-top: 20px;}
	.page-inner-title {margin-bottom: 20px;}
	.input-holder input{font-size: 14px;}
	.dropdown-header p {font-size: 14px;}
	.wallet-address-card .scan-wallet-img img {width: 180px;}
	.z-theme-wrapper .btn-lg {font-size: 15px; padding: 7px 27px;}
	.connect-exchange-left{padding: 13px;}
	.pricing-value h3{font-size: 19px;}
	.scan-wallet-img canvas {height: 206px !important; width: 206px !important;}
	.header-page-title h3{font-size: 17px;}
	.setting-right-main-card-header h3 {font-size: 20px; margin-bottom: 6px;}
	.main-menu-list-item{font-size: 15px;}

}


@media (max-width: 1024px){
	.ds-main-card-body {gap: 15px; grid-gap: 15px;}
	.ds-main-card{padding: 13px;}
	.ds-main-left-icon span{height: 30px; width: 30px;}
	.ds-main-left-icon span svg{height: 18px; width: 18px;}
	.ds-main-right span{font-size: 11px; margin-bottom: 2px;}
	.ds-main-right h3{font-size: 15px;}
	.token-img-detail p{font-size: 11px;}
	.ds-main-card .ds-main-dropdown .dropdown-toggle svg {height: 11px; width: 12px;}
	.ds-main-card.ds-page-main-card .ds-main-card-body{    flex-direction: column; align-items: flex-start;}
	.pricing-card-popular{padding: 25px 25px 18px;}
	.connect-exchange-left{margin-bottom: 30px;}
	.token-name, .token-percent{font-size: 10px;}
	.pricing-list-item p{font-size: 13px;}
	.pricing-list-item p{padding: 6px 0px;}
	.pricing-more-list span{padding: 4px 0px;}
	.affiliate-card .aff-card-head,
	.ds-main-card.affiliate-card,
	.aff-card-body{padding: 8px;}
	.affiliate-card .aff-card-head h4{font-size: 13px;}
	.aff-card-body h3{font-size: 18px;}
	.aff-card-body p{font-size: 11px;}
	.main-wrapper .container-fluid {width: 95%;}
	.setting-tabs-left .nav-pills .nav-link{padding: 15px 7px; margin-bottom: 8px;}
	.tab-pill-left-detail{gap: 10px;}
	.tab-pill-left-detail h4{font-size: 15px;}
	.subscription-inner-tabs .nav.nav-pills{gap: 6px;}
	.kyc-tab-detail h3{font-size: 12px;}
	.kyc-tabs-holder .subscription-inner-tabs .nav-pills .nav-item .nav-link{width: 180px; min-width: 180px; gap: 7px;}
	.kyc-tabs-holder .subscription-inner-tabs .nav-pills .nav-item .nav-link span{font-size: 13px;}
	.kyc-tabs-holder .subscription-inner-tabs .nav-pills .nav-item .nav-link svg{font-size: 18px;}
	.help-card-body img {margin-bottom: 16px; width: 44px; height: 44px;}
	.help-card-body p{font-size: 15px;}	
	.help-card-body h3{font-size: 26px; margin-bottom: 4px;}
	.support-card {min-height: 150px;}
	.ds-card-title.support-title p {font-size: 14px; margin-bottom: 0;}
	.z-aside-holder .z-side-bar .z-drawer-menu{padding: 1.2rem 0 0px 0px;}
	.theme-table tbody tr td {padding: 7px 6px !important; font-size: 12px !important; font-weight: 300;}
	.theme-table .table thead tr th .table-head-th span{font-size: 12px;}
	.account-type-td ul li span{font-size: 11px; font-weight: 200;}
	.theme-table tbody tr td .btn.btn-sm.btn-dark{font-size: 12px !important; border-radius: 3px !important; padding: 6px 3px !important; width: 120px;}
	.main-header-left{gap: 30px;}
	.logo a img{width: 120px;}
	.main-menu{gap: 35px;}
	.feature-card p{font-size: 16px;}
	.feature-card{padding: 20px;}
	.feature-card img{ margin-bottom: 20px; width: 60px;}
	.total-affiliate-benifits h4,
	.total-affiliate-benifits p{font-size: 20px;}
	.total-affiliate-benifits h3{font-size: 70px;}
	.aff-covert-holder-left h3 {font-size: 16px !important;}
	.aff-covert-holder-left p{font-size: 21px !important;}
	.start-how-list-holder{column-gap: 85px;}
	.start__item:before {content: ""; left: 166px; top: 80px; width: 10px;}
	.support-exchange-tbl-title h3{font-size: 19px;}
	.token-img-detail span {font-size: 12px; font-weight: 400;}
	.z-drawer-menu li a {padding: 14px 10px; font-size: 15px;}
	.token-img{gap: 7px;}
	.ds-card-title h3,
	.page-inner-title h2{font-size: 19px;}
	.table-pagination{margin-top: 20px;}
	.page-inner-title {margin-bottom: 20px;}
	.input-holder input{font-size: 14px;}
	.dropdown-header p {font-size: 14px;}
	.wallet-address-card .scan-wallet-img img {width: 180px;}
	.z-theme-wrapper .btn-lg {font-size: 15px; padding: 7px 27px;}
	.connect-exchange-left{padding: 13px;}
	.pricing-value h3{font-size: 19px;}
	.scan-wallet-img canvas {height: 206px !important; width: 206px !important;}
	.header-page-title h3{font-size: 17px;}
	.setting-right-main-card-header h3 {font-size: 20px; margin-bottom: 6px;}
	.main-menu-list-item{font-size: 14px;}
}

@media (min-width: 768px) and (max-width: 990px) {
}

@media (min-width: 600px) and (max-width: 767px) {
}

@media (min-width: 320px) and (max-width: 599px) {
	.mobile-show{display: flex !important;}
	.main-ds-header{display: none;}
	.logo a{color: #fff; font-size: 20px;}
	.col-lg-6.text-right.col-2.mobile-nav{display: flex; align-items: center; justify-content: flex-end;}
	.mobile-nav-bar{margin-top: 5px;}
	.section-padding{padding: 50px 0px;}
	.spot-tabs{overflow: hidden;}
	.banner-caption-holder h2{font-size: 30px; margin-bottom: 12px;}
	.login-left{display: none;}
	.banner-btn .btn {padding: 10px 30px; font-size: 15px;}
	.banner-caption-holder p {font-size: 17px; line-height: 27px;margin-bottom: 12px;}
	.login-right{width: 100%; padding: 78px 20px 98px;}
	.login-btn-form p{font-size: 14px;}
	.banner-vec-images{display: none;}
	.main-banner.section-padding{padding: 50px 0px 60px 0px;}
	.login-btn-form p a{font-size: 17px;}
	.total-vol-card h2{font-size: 50px; margin-bottom: 10px;}
	.total-vol-card {padding: 20px; border:1px solid #1b54ab4a; margin-bottom: 20px; border-radius: 10px;}
	.total-vol-card p{font-size: 15px;}
	.z-aside-structure .z-side-bar{left: -100%;}
	.main-wrapper.z-aside-structure.aside-active .z-aside-structure .z-side-bar{left: 0%;}
	.z-theme-wrapper{padding-left: 0;}
	.z-theme-wrapper .section-padding{padding-top: 110px;}
	.z-main-header{right: 0; width: 100%;}
	.main-wrapper.z-aside-structure.aside-active .z-theme-wrapper{padding-left: 300px;}
	.main-wrapper.z-aside-structure.aside-active .z-main-header{right: auto;}
	.ds-page-main-card{margin-bottom: 20px;}
	.connect-exchange-left ul li p a{display: contents;}
	.select-exchange-input{margin-top: 30px;}
	.header-page-title{gap: 8px;}
	.header-page-title h3{font-size: 16px;}
	.z-main-nav-right ul{gap: 10px; padding-right: 0 !important;}
	.subscription-main-tabs .nav.nav-pills{flex-direction: column; width: 100%;}
	.subscription-main-tabs .nav.nav-pills .nav-item .nav-link {font-size: 16px;padding: 11px; background: #eee; width: 100%; display: flex; align-items: center; justify-content: center; line-height: 21px;}
	.subscription-main-tabs .nav.nav-pills .nav-item .nav-link.active {background-color: #0f4ba7; color: #fff; border-bottom: 2px solid #0f4ba7;}
	.subscription-main-tabs .nav.nav-pills{gap: 13px;}
	.pricing-card-holder{margin-bottom: 20px; height: auto;}
	.plans { margin: 0 0px 40px 0px; flex-direction: column; align-items: flex-start; padding: 0px;}
	.portfolio-head-right .btn,
	.btn-support-holder .btn{font-size: 14px !important; padding: 8px 15px !important;}
	.ds-main-card.affiliate-card{height: auto; margin-bottom: 20px;}
	.term-affiliate-holder h3 a {display: contents;}
	.support-card{height: auto; margin-bottom: 20px;}
	.ds-card-title.support-title h3{font-size: 17px;}
	.not-found-holder{padding: 20px;}
	.z-aside-structure.dark-mode-wrapper .subscription-main-tabs .nav.nav-pills .nav-item .nav-link.active {color: #fff; border: 2px solid #267bff;}
	.z-aside-structure.dark-mode-wrapper .subscription-main-tabs .nav.nav-pills .nav-item .nav-link {border: 1px solid #100f0f; border-bottom: 1px solid #100f0f; background: #2c313a;}
	.z-aside-structure.dark-mode-wrapper .subscription-main-tabs .nav.nav-pills{border-bottom: transparent;}
	.exchanges-desk{display: none;}
	.exchanges-mobile{display: block !important; margin-top: 50px;}
	.banner-caption-holder{margin-bottom: 30px;}
	.main-heading h2 {font-size: 30px;line-height: 42px; margin-bottom: 15px;}
	.main-heading p {margin-bottom: 23px; font-size: 16px;line-height: 24px;}
	.wrapper .section-padding {padding: 50px 0;}
	.section-padding.advance-feature-holder .main-heading.text-center{text-align: left !important;}
	.section-padding.advance-feature-holder .row.justify-content-center.mb-80{margin-bottom: 30px;}
	.mobile-app-soon .main-heading h2 { font-size: 26px; line-height: 39px;}
	.mobile-soon-img{display: none;}
	.section-padding.mobile-app-soon{padding: 50px 0px 60px 0px !important;}
	.section-padding.security-holder .row.justify-content-center.mb-60{margin-bottom: 30px;}
	.secure-card h3 { font-size: 19px;margin-bottom: 10px;}
	.secure-card p {font-size: 14px;line-height: 20px;}
	.secure-card{margin-bottom: 30px;}
	.total-affiliate-benifits{text-align: left; margin-top: 30px;}
	.total-affiliate-benifits h4 {font-size: 15px; margin-bottom: 10px;}
	.total-affiliate-benifits h3 {font-size: 50px;}
	.total-affiliate-benifits p{font-size: 18px;}
	.footer-menu-holder {margin-bottom: 22px;}
	.footer-menu-holder h4{font-size: 18px; margin-bottom: 10px;}
	.footer-menu-holder ul li{margin-bottom: 7px;}
	.footer-logo-holder{margin-bottom: 30px;}
	.footer-logo-holder p{margin-bottom: 10px;}
	.main-footer {padding: 40px 0px;}
	.section-padding.security-holder .main-heading.text-center,
	.section-padding.pricing-plan-section .main-heading.text-center{text-align: left !important;}
	.start__item:before{content: ""; display: none !important;}
	.start__number{left: 34px;}
	.start__item{margin-bottom: 20px;}
	.start__item:last-child{margin-bottom: 0;}
	.subscription-inner-tabs.trade-api-tabs-holder .nav.nav-pills{flex-direction: column;}
	.subscription-inner-tabs.trade-api-tabs-holder .nav.nav-pills .nav-item .nav-link{width: 100%; display: block;}
	.connect-exchange-left{margin-bottom: 30px;}
	.ds-main-card.closed{ height: 60px; overflow: hidden; transition: 0.4s;}
	.toggle-card{display: inline !important; position: absolute; right: 15px; outline: 0; border: 0; padding: 0; margin: 0; font-size: 19px; top: 17px;}
	.ds-main-card.open{height: 100%; overflow: auto; transition: 0.4s;}
	.copy-wallet-subscription input{font-size: 10px !important; padding: 6px !important; font-weight: 500 !important; }
	.copy-wallet-subscription.copy-ip-input .btn.btn-theme.btn-sm {font-size: 6px; padding: 4px; top: 45px; right: 6px;}
	.copy-wallet-subscription.copy-ip-input .btn.btn-theme.btn-sm  svg {height: 12px  !important; width: 12px !important;}
	.note-wallet-holder p{font-size: 12px;}
	.wallet-address-card h3 {font-size: 17px; font-weight: 500; margin-bottom: 30px;}
	.page-inner-title h2{font-size: 18px;}
	.setting-right-main-card{margin-top: 30px;}
	.setting-tabs-left .nav-pills .nav-link {padding: 11px 13px; margin-bottom: 10px;}
	.tab-pill-left-detail h4{font-size: 15px;}
	.setting-right-main-card-header h3{font-size: 18px;}
	.z-theme-wrapper .btn-lg {font-size: 15px; padding: 7px 24px;}
	.theme-table tbody tr td{font-size: 12px; font-weight: 300;}
	.token-img-detail span{font-size: 12px;}
	.account-type-td ul li span{text-align: center; vertical-align: middle; line-height: 20px;    display: inline-table; width: 60px; height: 23px; border-radius: 4px;}
	.copy-ip-input .btn.btn-theme.btn-sm {background-color: #fff; top: 61px;}



}




