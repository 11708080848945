.z-aside-structure.dark-mode-wrapper .z-main-header,
.z-aside-structure.dark-mode-wrapper .z-aside-structure .z-side-bar,
.z-aside-structure.dark-mode-wrapper .z-aside-holder .z-manu-holder,
.z-aside-structure.dark-mode-wrapper .ds-main-card{
    background: #1a1c22;
    transition: 0.5s;
}
.z-aside-structure.dark-mode-wrapper .ds-main-card {
    border: 1px solid #1a1c22;
}

.z-aside-structure.dark-mode-wrapper .subscription-main-tabs .nav.nav-pills .nav-item .nav-link .count-badge {
  background-color: #fff;
  color: #000;
}

.z-aside-structure.dark-mode-wrapper .header-page-title .menu-toggle-btn {
    background: #ffffff4a;
    width: 35px;
    height: 33px;
    border-radius: 4px;
    color: #fff;
    transition: 0.5s;
}

.z-aside-structure.dark-mode-wrapper .ds-main-left-icon span{
    background-color: #5c5e628c;
    transition: 0.5s;
}

.z-aside-structure.dark-mode-wrapper .ds-main-right span,
.z-aside-structure.dark-mode-wrapper .theme-table .table thead tr th .table-head-th span {
    color: #eaeaea;
    transition: 0.5s;
}

.z-aside-structure.dark-mode-wrapper .header-page-title h3,
.z-aside-structure.dark-mode-wrapper .z-manu-holder a,
.z-aside-structure.dark-mode-wrapper .z-manu-holder button,
.z-aside-structure.dark-mode-wrapper .z-drawer-menu li a,
.z-aside-structure.dark-mode-wrapper .z-drawer-menu li button,
.z-aside-structure.dark-mode-wrapper .page-inner-title h2,
.z-aside-structure.dark-mode-wrapper .ds-main-right h3,
.z-aside-structure.dark-mode-wrapper .ds-card-title h3,
.z-aside-structure.dark-mode-wrapper tbody tr.tr-bg-dark td,
.z-aside-structure.dark-mode-wrapper tbody tr td{
    color: #fff;
    transition: 0.5s;
}

.z-aside-structure.dark-mode-wrapper .z-main-nav-right ul li a svg,
.z-aside-structure.dark-mode-wrapper .z-main-nav-right ul li button svg,
.z-aside-structure.dark-mode-wrapper .z-drawer-menu li a svg,
.z-aside-structure.dark-mode-wrapper .z-drawer-menu li button svg,
.z-aside-structure.dark-mode-wrapper .ds-main-left-icon span svg,
.z-aside-structure.dark-mode-wrapper .table-head-th button svg {
    fill: #fff !important;
    transition: 0.5s;
}

.z-aside-structure.dark-mode-wrapper .z-aside-structure .z-side-bar{
    border-right:1px solid #767a7e75;
    transition: 0.5s;
}

.z-aside-structure.dark-mode-wrapper .z-drawer-menu li a.active,
.z-aside-structure.dark-mode-wrapper .z-drawer-menu li button.active,
.z-aside-structure.dark-mode-wrapper .z-drawer-menu li a.active:hover,
.z-aside-structure.dark-mode-wrapper .z-drawer-menu li button.active:hover,
.z-aside-structure.dark-mode-wrapper .z-drawer-menu li a:hover,
.z-aside-structure.dark-mode-wrapper .z-drawer-menu li button:hover {
    background: #2c313a;
    transition: 0.5s;
    color: #fff;
}

.z-aside-structure.dark-mode-wrapper .z-aside-holder .z-manu-holder{
    border-bottom: 1px solid #767a7e4a;
    transition: 0.5s;
}

.z-aside-structure.dark-mode-wrapper .z-theme-wrapper .section-padding {
    background-color: #2c313a;
    transition: 0.5s;
}

.z-aside-structure.dark-mode-wrapper .toggle-slot{
    border: 1px solid #1a1c22;
}

.z-aside-structure.dark-mode-wrapper .ds-main-card .dropdown .dropdown-toggle svg circle{
    fill: #a5a8af !important;
}

.z-aside-structure.dark-mode-wrapper .theme-table .table thead tr{
    border: 1px solid #2c344a;
}

.z-aside-structure.dark-mode-wrapper .theme-table .table thead tr th{
    background-color: #1a1c22;
}
.z-aside-structure.dark-mode-wrapper tbody tr.tr-bg-dark td{
    background-color: #272a32;
    border-bottom: 1px solid #1a1c22;
}

.z-aside-structure.dark-mode-wrapper tbody tr td{
    background-color: #1a1c22;
    border-bottom: 1px solid #1a1c22;

}
.z-aside-structure.dark-mode-wrapper .account-type-td ul li span{
    background-color: #3e4141;
}
.z-aside-structure.dark-mode-wrapper .input-holder input,
.z-aside-structure.dark-mode-wrapper .input-holder select,
.z-aside-structure.dark-mode-wrapper .input-holder textarea{
    border: 1px solid #3d4450 !important;
    color: #ffff !important;
}

.z-aside-structure.dark-mode-wrapper .input-holder input::placeholder,
.z-aside-structure.dark-mode-wrapper .input-holder label,
.z-aside-structure.dark-mode-wrapper .input-holder select::placeholder,
.z-aside-structure.dark-mode-wrapper .subscription-main-tabs .nav.nav-pills .nav-item .nav-link,
.z-aside-structure.dark-mode-wrapper .pricing-value h3,
.z-aside-structure.dark-mode-wrapper .pricing-list-item p,
.z-aside-structure.dark-mode-wrapper .pricing-list-item p span,
.z-aside-structure.dark-mode-wrapper .pricing-more-list span,
.z-aside-structure.dark-mode-wrapper .pricing-more-list p,
.z-aside-structure.dark-mode-wrapper .affiliate-card .aff-card-head h4,
.z-aside-structure.dark-mode-wrapper .aff-card-body h3,
.z-aside-structure.dark-mode-wrapper .ds-card-title p,
.z-aside-structure.dark-mode-wrapper .aff-card-body p,
.z-aside-structure.dark-mode-wrapper .page-title h2,
.z-aside-structure.dark-mode-wrapper .prt-main-card-head-left h3,
.z-aside-structure.dark-mode-wrapper .prt-total-statistics-left span,
.z-aside-structure.dark-mode-wrapper .prt-total-statistics-blnc p,
.z-aside-structure.dark-mode-wrapper .profile-card ul li p,
.z-aside-structure.dark-mode-wrapper .profile-card ul li h4,
.z-aside-structure.dark-mode-wrapper .update-info h3,
.z-aside-structure.dark-mode-wrapper  .z-theme-wrapper .btn-outline-secondary:hover,
.z-aside-structure.dark-mode-wrapper .plans .plan .plan-details span,
.z-aside-structure.dark-mode-wrapper .billing-card-header h3,
.z-aside-structure.dark-mode-wrapper .billing-card-header p,
.z-aside-structure.dark-mode-wrapper .business-info-heading h3,
.z-aside-structure.dark-mode-wrapper .term-affiliate-holder h3,
.z-aside-structure.dark-mode-wrapper .trade-page-holder .page-title-trade h2,
.z-aside-structure.dark-mode-wrapper .connect-exchange-left h4,
.z-aside-structure.dark-mode-wrapper .select-exchange-input label,
.z-aside-structure.dark-mode-wrapper .quick-connect-holder p,
.z-aside-structure.dark-mode-wrapper .help-card-body h3{
    color: #fff;
}

.z-aside-structure.dark-mode-wrapper .help-card-body p{
    color: #a19d9d;
}

.z-aside-structure.dark-mode-wrapper .form-control:disabled {
    background-color: #2c313a;
}



.z-aside-structure.dark-mode-wrapper .subscription-inner-tabs .nav.nav-pills .nav-item .nav-link.active {
    background-color: #1a1c22;
    border: 1px solid #1a1c22;

    color: #fff;
}

.z-aside-structure.dark-mode-wrapper .profile-card ul li{
    border:1px solid #3d4450 !important;
}

.z-aside-structure.dark-mode-wrapper .subscription-inner-tabs .nav.nav-pills .nav-item .nav-link{
    background-color: transparent;
    border: 1px solid #1a1c22;
    color: #fff;
}

.z-aside-structure.dark-mode-wrapper .upload-btn-wrapper .btn {
    border: 2px dashed #3d4450;
    color: #fff;
}

.z-aside-structure.dark-mode-wrapper .pricing-card-holder {
    background-color: #1a1c22;
}
.z-aside-structure.dark-mode-wrapper .pricing-card-popular{
    background: linear-gradient(rgb(26 28 34), rgb(26 28 34)) padding-box padding-box, linear-gradient(90deg, rgb(20, 201, 193), rgb(28, 230, 221), rgb(12, 199, 228), rgb(30, 114, 210)) border-box border-box;
}

.z-aside-structure.dark-mode-wrapper  .z-theme-wrapper .btn-outline-secondary,
.z-aside-structure.dark-mode-wrapper .prt-main-card-head-left p{
    color: #e1e1e2;
}

.z-aside-structure.dark-mode-wrapper .prt-main-card-head-right button svg,
.z-aside-structure.dark-mode-wrapper .account-footer-right .dropdown button svg path{
    fill:  #fff !important;
}

.z-aside-structure.dark-mode-wrapper .token-name,
.z-aside-structure.dark-mode-wrapper .token-percent{
    color: #9ba0a5;
}

.z-aside-structure.dark-mode-wrapper .connect-exchange-left ul li p{
    color: rgb(170 176 189);
}

.z-aside-structure.dark-mode-wrapper .term-affiliate-holder ul li p{
    color:#d3d3d3;
}

.z-aside-structure.dark-mode-wrapper .term-affiliate-holder h3 a,
.z-aside-structure.dark-mode-wrapper .term-affiliate-holder ul li p a {
    color: #458efe;
}

.z-aside-structure.dark-mode-wrapper .aff-card-body p span {
    color: #b6baff;
}

.z-aside-structure.dark-mode-wrapper .plans .plan .plan-details p{
    color: #a9adb5;
}

.z-aside-structure.dark-mode-wrapper .affiliate-card .aff-card-head{
    border-bottom: 1px solid #3c414e;
}

.z-aside-structure.dark-mode-wrapper .pricing-value .btn-buy{
    color: #fff;
    background-color: rgb(44 49 58);
}

.z-aside-structure.dark-mode-wrapper  .plans .plan input[type="radio"]:checked + .plan-content {
    background-color: #2c313a;
    border: 2px solid #555e6b;
}

 .z-aside-structure.dark-mode-wrapper .billing-info-tabs-holder .subscription-inner-tabs .nav.nav-pills .nav-item .nav-link.active {
    background-color: #2c313a;
 }

 
 .z-aside-structure.dark-mode-wrapper .setting-tabs-left .nav-pills .nav-link.active,
 .z-aside-structure.dark-mode-wrapper  .setting-right-main-card {
    background-color: #1a1c22;
    border: 1px solid #313f52;
 }

 .z-aside-structure.dark-mode-wrapper .setting-tabs-left .nav-pills .nav-link.active .tab-pill-left-detail span {
    background: #2c313a;
 }

 .z-aside-structure.dark-mode-wrapper .setting-tabs-left .nav-pills .nav-link.active .tab-pill-left-detail h4,
 .z-aside-structure.dark-mode-wrapper .setting-tabs-left .nav-pills .nav-link .tab-pill-left-detail h4,
 .z-aside-structure.dark-mode-wrapper .setting-tabs-left .nav-pills .nav-link.active .tab-pill-right-detail svg,
 .z-aside-structure.dark-mode-wrapper .setting-tabs-left .nav-pills .nav-link .tab-pill-right-detail svg,
 .z-aside-structure.dark-mode-wrapper .setting-right-main-card-header h3,
 .z-aside-structure.dark-mode-wrapper .kyc-tab-detail h3,
 .z-aside-structure.dark-mode-wrapper .kyc-tab-detail p,
 .z-aside-structure.dark-mode-wrapper .kyc-tab-detail ul li span {
    color: #fff;
 }


 .z-aside-structure.dark-mode-wrapper .setting-right-main-card-header p {
    color: #d8d7d7;
}

 .z-aside-structure.dark-mode-wrapper .kyc-tabs-holder .subscription-inner-tabs .nav.nav-pills .nav-item .nav-link.active {
    background-color: #2c313a;
 }

 .z-aside-structure.dark-mode-wrapper .kyc-tabs-holder .subscription-inner-tabs .nav.nav-pills .nav-item .nav-link {
    background-color: #292a2f;
 }

 
 .z-aside-structure.dark-mode-wrapper .dropdown-header {
    border: 1px solid #3d4450 !important;
 }
 .z-aside-structure.dark-mode-wrapper  .dropdown-list {
    background-color: #2c313a;
 }

 .z-aside-structure.dark-mode-wrapper .dropdown-header p,
 .z-aside-structure.dark-mode-wrapper .dropdown-list li p,
 .z-aside-structure.dark-mode-wrapper .search-result-holder .search-result p,
 .z-aside-structure.dark-mode-wrapper .search-result-holder .search-result span{
    color: #fff;
 }

 .z-aside-structure.dark-mode-wrapper  .search-exchange-holder {
    border-bottom: 1px solid #575d6e;
 }


 .z-aside-structure.dark-mode-wrapper .table-pagination nav .pagination .page-link {
    color: #ffffff;
    border: 1px solid #1a1c22;
    background: #1a1c22;
 }

 .z-aside-structure.dark-mode-wrapper  .spot-tabs{
    background-color: #2c313a;
 }

 .z-aside-structure.dark-mode-wrapper .trade-card{
    background-color: #1a1c22;
    border: 1px solid #3f465c;
 }

 .z-aside-structure.dark-mode-wrapper .search-trade-left input {
    border: 1px solid #242020;
    margin-bottom: 20px;
    background: #2c313a;
 }

 .z-aside-structure.dark-mode-wrapper .trading-pair-holder .nav .nav-item .nav-link,
 .z-aside-structure.dark-mode-wrapper .pair-last-price,
 .z-aside-structure.dark-mode-wrapper .order-card-heading h4,
 .z-aside-structure.dark-mode-wrapper .cuurent-pair-price li h4,
 .z-aside-structure.dark-mode-wrapper .spot-tabs-holder .nav .nav-item .nav-link,
 .z-aside-structure.dark-mode-wrapper .limit-form form h4,
 .z-aside-structure.dark-mode-wrapper .support-exchange-tbl-title h3{
    color: #fff;
 }

 .z-aside-structure.dark-mode-wrapper .trading-pair-holder .nav .nav-item .nav-link.active, 
 .z-aside-structure.dark-mode-wrapper .trading-pair-holder .nav .nav-item .nav-link.active .form-select,
 .z-aside-structure.dark-mode-wrapper .spot-tabs-holder .nav .nav-item .nav-link.active{
    color: #ffbe40;
 }

 .z-aside-structure.dark-mode-wrapper .trading-pair-accordian .accordion .accordion-item {
    background: #2c313a;
 }

 .z-aside-structure.dark-mode-wrapper .trading-pair-list-left h5{
    color: #d8d8d8;
 }

 .z-aside-structure.dark-mode-wrapper .subscription-main-tabs .nav.nav-pills{
    border-bottom: 1px solid #1a1c22;
 }

 .z-aside-structure.dark-mode-wrapper .connect-wallet-td ul li .connect-direct{
    color: #f7fbff;
    background-color: #2c313a;
    box-shadow: inset 0 0 0 1px #35383b;
 }

 .z-aside-structure.dark-mode-wrapper .trading-pair-list li:hover {
    background-color: #1a1c22;
}

.z-aside-structure.dark-mode-wrapper .l-inp-holder input{
    border: 1px solid #3e4552;
    color: #fff;
    background-color: #2c313a;
}

.z-aside-structure.dark-mode-wrapper .limit-form form .text-red {
    color: #ff5761 !important;
}

.z-aside-structure.dark-mode-wrapper .limit-form form .text-green {
    color: #00c186 !important;
}

.z-aside-structure.dark-mode-wrapper .page-bradcrumbs nav .breadcrumb .breadcrumb-item{
    color: #fff;
}

.z-aside-structure.dark-mode-wrapper .page-bradcrumbs nav .breadcrumb .breadcrumb-item.active a,
.z-aside-structure.dark-mode-wrapper .page-bradcrumbs nav .breadcrumb .breadcrumb-item a {
    color: #5891ef;
}

.z-aside-structure.dark-mode-wrapper .page-bradcrumbs .breadcrumb-item+.breadcrumb-item::before,
.z-aside-structure.dark-mode-wrapper .wallet-address-card h3,
.z-aside-structure.dark-mode-wrapper .note-wallet-holder p {
    color: #fff;
}

.z-aside-structure.dark-mode-wrapper .wallet-or-holder {
    border-top: 1px solid #2c313a;
    border-bottom: 1px solid #2c31
}

.z-aside-structure.dark-mode-wrapper .z-theme-wrapper .alert-success{
    background: #45574f;
    border: 1px solid #557164;
    color: #aaddba;
}

.z-aside-structure.dark-mode-wrapper .z-theme-wrapper .alert-danger{
    background: #5b4747;
    border: 1px solid #856464;
    color: #fcb1b1;
}
.z-aside-structure.dark-mode-wrapper .z-theme-wrapper .alert-warning{
    background: #332a1b;
    border: 1px solid;
    color: #dad09d;
}
.z-aside-structure.dark-mode-wrapper .z-theme-wrapper .alert-info {
    background: #283d40;
    border: 1px solid;
    color: #abf7f6;
}

.z-aside-structure.dark-mode-wrapper .z-theme-wrapper .bg-dark-wallet{
    display: none;
}

.z-aside-structure.dark-mode-wrapper .z-theme-wrapper .bg-white-wallet{
    display: inline-block !important;
    text-align: center;
}

.z-aside-structure.dark-mode-wrapper .modal-dialog .modal-content {
    background-color: #2c313a;
}

.z-aside-structure.dark-mode-wrapper .modal-dialog .modal-content {

}

.z-aside-structure.dark-mode-wrapper .modal-dialog .modal-content .modal-header{
    border-bottom: 1px solid #556178;
}

.z-aside-structure.dark-mode-wrapper .modal-dialog .modal-content .modal-footer{
    border-top: 1px solid #556178;
}

.z-aside-structure.dark-mode-wrapper .modal-dialog .modal-content .modal-header h1,
.z-aside-structure.dark-mode-wrapper .modal-dialog .modal-content .modal-header h2,
.z-aside-structure.dark-mode-wrapper .modal-dialog .modal-content .modal-header h3,
.z-aside-structure.dark-mode-wrapper .modal-dialog .modal-content .modal-header h4,
.z-aside-structure.dark-mode-wrapper .modal-dialog .modal-content .modal-header p,
.z-aside-structure.dark-mode-wrapper .modal-dialog .modal-content .modal-body p,
.z-aside-structure.dark-mode-wrapper .modal-dialog .modal-content .modal-body h1,
.z-aside-structure.dark-mode-wrapper .modal-dialog .modal-content .modal-body h2,
.z-aside-structure.dark-mode-wrapper .modal-dialog .modal-content .modal-body h3,
.z-aside-structure.dark-mode-wrapper .modal-dialog .modal-content .modal-body h4 {
    color: #fff;
}

.z-aside-structure.dark-mode-wrapper .modal-dialog .modal-content .modal-body .success-request-alert p {
    color: #000;
}

.z-aside-structure.dark-mode-wrapper .modal-dialog .modal-content .modal-body .copy-ip-input label{
    color: #fff !important;
}