/*-----------------------------------------------------------------------------------
    Template Name   : Crypto Bot
    Developer       : Hunain Butt
    Description     : This is a CSS Template
    Version         : 1.1.0
-----------------------------------------------------------------------------------*/

/****** Theme Reset Style ***********************************************************
*********************************************************** Theme Reset Style ******/

/** online font **/
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
body.netro-body {
  font-size: 15px;
  color: #7e7e7e;
  font-weight: 500;
}
body.netro-body h1,
body.netro-body h2,
body.netro-body h3,
body.netro-body h4,
body.netro-body h5,
body.netro-body h6 {
  margin: 0 0 13px;
}

/***** Div style ****/
:root {
  --thm-font: "DM Sans", sans-serif;
  --thm-manrope-font: "Manrope", sans-serif;
  --thm-italianno-font: "Italianno", cursive;
  --thm-base: #0f54f9cc;
  --thm-base-rgb: 149, 77, 28;
  --thm-primary: #f94d1c;
  --thm-primary-rgb: 249, 77, 28;
  --thm-gray: #74787c;
  --thm-gray-rgb: 116, 120, 124;
  --thm-black: #1a1a1a;
  --thm-black-rgb: 26, 26, 26;
}

div {
  outline: none;
}

/******* Div Style ********/

p a {
  color: #000;
  text-decoration: none;
}
p a:hover {
  text-decoration: underline;
  outline: none;
}
p {
  letter-spacing: normal;
  margin: 0 0 20px;
  color: #7e7e7e;
  line-height: 22px;
  font-size: 17px;
}
a {
  color: #000;
  text-decoration: none;
  outline: none !important;
  display: block;
}
a:hover,
a:focus {
  outline: none;
  text-decoration: none;
  color: #000;
}
.btn:hover,
.btn:focus,
.btn.focus {
  outline: none;
  text-decoration: none;
  color: #fff;
}
.service-book-wrapper a {
  color: #fff;
  text-decoration: none;
}

.service-book-wrapper a:hover {
  text-decoration: none;
}

/****** Image Style ******/
img {
  max-width: 100%;
}
/***** iframe ****/
iframe {
  border: 0;
  width: 100%;
}
/**** blockquote ****/
blockquote {
  padding: 0;
  border: 0;
}
/****** From Element Style ******/
label {
  display: block;
  font-weight: 400;
  margin: 0;
}
textarea {
  border-radius: 0;
  resize: none;
}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
input,
.form-control {
  height: 45px;
  line-height: 50px;
  padding: 0 20px;
  border-radius: 4px;
  box-shadow: none;
  font-size: 13px;
  position: relative;
  font-weight: 300;
}
.form-control.border-0 {
  border: 0;
}
.form-control.bg-wihet {
  background: #fff;
}
.form-control::-webkit-input-placeholder {
  color: #999;
}
.form-control:-moz-placeholder {
  color: #999;
}
.form-control::-moz-placeholder {
  color: #999;
}
.form-control:-ms-input-placeholder {
  color: #999;
}
.form-control:focus::-webkit-input-placeholder {
  opacity: 0;
}
.form-control:focus:-moz-placeholder {
  opacity: 0;
}
.form-control:focus::-moz-placeholder {
  opacity: 0;
}
.form-control:focus:-ms-input-placeholder {
  opacity: 0;
}
/****** Theme Reset Style ***********************************************************
*********************************************************** Theme Reset Style ******/

/****** Global Elements ***********************************************************
*********************************************************** Global Elements ******/

/**** overlay ***/
.overlay-dark {
  position: relative;
}
.overlay-dark::before,
.overlay-dark::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.91);
  z-index: 1;
}
.overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.overlay-white {
  position: relative;
}

.overlay-white::before {
  position: absolute;
  top: 0px;
  content: "";
  left: 0px;
  bottom: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  pointer-events: none;
  text-align: center;
  background: radial-gradient(
    rgb(248 249 250 / 91%),
    rgba(248, 249, 250, 0.55)
  );
}
/**** row-no-gutter ***/
.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}
/******** Margin & Padding *******/
.netro-wrapper .mb-0 {
  margin-bottom: 0px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-100 {
  margin-bottom: 100px;
}

.mt-0 {
  margin-top: 0px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-90 {
  margin-top: 90px;
}
.mt-100 {
  margin-top: 100px;
}

.ml-0 {
  margin-left: 0px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-35 {
  margin-left: 35px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-45 {
  margin-left: 45px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-60 {
  margin-left: 60px;
}
.ml-70 {
  margin-left: 70px;
}
.ml-80 {
  margin-left: 80px;
}
.ml-90 {
  margin-left: 90px;
}
.ml-100 {
  margin-left: 100px;
}

.mr-0 {
  margin-right: 0px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-35 {
  margin-right: 35px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-45 {
  margin-right: 45px;
}
.mr-50 {
  margin-right: 50px;
}
.mr-60 {
  margin-right: 60px;
}
.mr-70 {
  margin-right: 70px;
}
.mr-80 {
  margin-right: 80px;
}
.mr-90 {
  margin-right: 90px;
}
.mr-100 {
  margin-right: 100px;
}

.pb-0 {
  padding-bottom: 0px;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-100 {
  padding-bottom: 100px;
}

.pt-0 {
  padding-top: 0px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-35 {
  padding-top: 35px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-45 {
  padding-top: 45px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-90 {
  padding-top: 90px;
}
.pt-100 {
  padding-top: 100px;
}

.pl-0 {
  padding-left: 0px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-25 {
  padding-left: 25px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-45 {
  padding-left: 45px;
}
.pl-50 {
  padding-left: 50px;
}
.pl-60 {
  padding-left: 60px;
}
.pl-70 {
  padding-left: 70px;
}
.pl-80 {
  padding-left: 80px;
}
.pl-90 {
  padding-left: 90px;
}
.pl-100 {
  padding-left: 100px;
}

.pr-0 {
  padding-right: 0px;
}
.pr-5 {
  padding-right: 5px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-25 {
  padding-right: 25px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-40 {
  padding-right: 40px;
}
.pr-45 {
  padding-right: 45px;
}
.pr-50 {
  padding-right: 50px;
}
.pr-60 {
  padding-right: 60px;
}
.pr-70 {
  padding-right: 70px;
}
.pr-80 {
  padding-right: 80px;
}
.pr-90 {
  padding-right: 90px;
}
.pr-100 {
  padding-right: 100px;
}

/******* margin & padding ********/

/**** Section-padding & section-margin ****/

.section-padding {
  padding: 130px 0;
}
.section-margin {
  margin: 100px 0;
}

/**** Section-padding & section-margin ****/

/***ul style***/

.main-wrapper ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
/**** hr style ****/
hr {
  margin: 30px 0px;
  width: 80%;
  margin: 0 auto;
}
/**** Truncate-Text ****/
.truncate-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/****** Home Page ***********************************************************
*********************************************************** Home Page ******/
.spot-heading h4 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}

.spot-tabs {
  padding: 130px 0px;
}

.mb-40 {
  margin-bottom: 40px;
}

.spot-tabs-holder .nav .nav-item .nav-link.active {
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #ffbe40;
  color: #ffbe40;
  border-radius: 0px;
}

.spot-tabs-holder .nav .nav-item .nav-link {
  border-radius: 0;
  padding-bottom: 3px;
  padding: 0;
  font-weight: 700;
  font-size: 13px;
  color: #505050;
}

.spot-tabs-holder .nav .nav-item {
  margin-right: 30px;
}

.spot-tabs-holder .nav .nav-item:last-child {
  margin-right: 0;
}

.limit-form {
  margin-top: 10px;
}

.limit-form form h4 {
  font-size: 15px;
  margin-bottom: 19px;
  color: rgba(0, 0, 0, 0.7);
}
.limit-form form .text-green {
  color: #00c186;
}

.limit-form form .text-red {
  color: #ff5761;
}

.l-inp-holder input {
  border: 1px solid #fff;
  background-color: #f3f4f7;
  height: 42px;
  color: #495057;
  font-size: 14px;
  font-weight: 500;
  position: relative;
}

.l-inp-holder input:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #ffbe40;
}

.l-inp-holder input:hover {
  border: 1px solid #ffbe40;
}

.mb-20 {
  margin-bottom: 20px;
}

.l-inp-holder {
  position: relative;
}

.l-inp-holder span {
  position: absolute;
  top: 11px;
  right: 35px;
  color: #9c9c9c;
  font-size: 14px;
  font-weight: 500;
}

.mb-30 {
  margin-bottom: 30px;
}

.btn-spot {
  height: 42px;
  width: 100%;
  font-size: 16px;
  border-radius: 4px;
  color: #fff;
  transition: 0.2s ease-in-out;
  white-space: nowrap;
  display: block;
}

.btn-buy {
  background-color: #00c186;
  color: #fff;
  border: 1px solid #00c186;
}

.btn-buy:hover {
  background-color: #019c6e;
}

.btn-sell {
  background-color: #ff5761;
  color: #fff;
  border: 1px solid #ff5761;
}
.btn-sell:hover {
  background-color: #cc454e;
}

.l-inp-holder.mb-20 .form-control.text-green::placeholder {
  color: #00c186 !important;
}

.l-inp-holder.mb-20 .form-control.text-red::placeholder {
  color: #ff5761 !important;
}

.mb-70 {
  margin-bottom: 70px;
}

.spot-table .tbody {
  border-top: 0px !important;
}

.spot-table thead tr th {
  color: #b9b9ba;
  font-size: 14px;
  line-height: 1.2;
}

.spot-table tbody {
  border: 0 !important;
}

.spot-table tbody tr th,
.spot-table tbody tr td {
  border: 0 !important;
}

.logo a {
  font-size: 22px;
  color: #fff;
  font-weight: 500;
}

.main-header {
  padding: 14px 0px;
  background-color: #000;
}

.header-menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-login-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-menu li {
  margin-right: 45px;
}

.header-menu li:last-child {
  margin-right: 0;
}

.header-menu li a {
  font-size: 17px;
  color: #fff;
}

.header-login-btn li a {
  color: #fff;
}

.header-login-btn li {
  margin-right: 20px;
}

.header-login-btn li:last-child {
  margin-right: 0;
}

.btn-white {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.btn-white:hover {
  color: #000;
  border: 1px solid #fff;
  background-color: #fff;
}

.main-wrapper .container-fluid {
  width: 90%;
}
.input-holder {
  position: relative;
}

.input-holder label {
  font-size: 15px;
  color: #202020;
  font-weight: 400;
  margin-bottom: 10px;
}

.input-holder select,
.input-holder select:focus{
  width: 100%;
  height: 45px;
  font-size: 15px !important;
  font-weight: 500 !important;
  color: #000 !important;
  background: transparent;
  border: 1px solid #dcdbdd !important;
  border-radius: 3px;
}

.input-holder input::placeholder{
  color: #353232;
}

.input-holder input:focus {
  box-shadow: none;
  border: 1px solid #dcdbdd;
  background-color: transparent;
  outline: none;
}



.login-form h4 {
  font-size: 25px;
  margin-bottom: 20px;
}

.btn-theme {
  background-color: #0f4ba7;
  color: #fff !important;
  border: 1px solid #0f4ba7;
}

.btn-theme:hover {
  border: 1px solid #0f4ba7;
  color: #0f4ba7 !important;
  background-color: transparent;
}


.btn-outline-theme {
  background-color: transparent;
  color: #0f4ba7;
  border: 1px solid #0f4ba7;
}

.btn-outline-theme:hover {
  border: 1px solid #0f4ba7;
  color: #fff;
  background-color: #0f4ba7;
}

.login-btn-form {
  display: flex;
  width: 100%;
  align-items: center;
}

.login-btn-form p {
  margin-left: 15px;
  display: flex;
  margin-bottom: 0;
  font-size: 18px;
  color: #000;
}

.btn-dark {
  background-color: #0f4ba7 !important;
  color: #fff !important;
  border: 1px solid #0f4ba7 !important;
}

.btn-dark:hover{
  background-color: transparent !important;
  color: #0f4ba7 !important;
  border: 1px solid #0f4ba7 !important;
}

.login-btn-form p a {
  margin-left: 5px;
  font-size: 22px;
}

.trade-card {
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 8%);
  background: #fff;
  padding: 20px 10px;
  border: 1px solid #eee;
  border-radius: 5px;
}

.search-trade-left input {
  height: 35px;
  width: 100%;
  border-radius: 50px;
  border: 1px solid #eee;
  margin-bottom: 20px;
}

.search-trade-left input:focus {
  outline: none;
  box-shadow: none;
}

.trading-pair-holder .nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trading-pair-holder .nav .nav-item .nav-link {
  padding: 0px 10px 0px 0px;
  font-size: 12px;
  font-weight: 800;
  color: #000;
}
.trading-pair-holder .nav .nav-item .nav-link.active {
  background-color: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #000;
}

.trading-pair-holder .nav .nav-item .nav-link .form-select {
  font-size: 12px;
  width: 60px;
  font-weight: 800;
  padding: 0;
  border: 0 !important;
  border-radius: 0px;
}

.trading-pair-holder .nav .nav-item .nav-link .form-select:focus {
  box-shadow: none;
  outline: none;
}

.trading-pair-holder .nav .nav-item .nav-link.active,
.trading-pair-holder .nav .nav-item .nav-link.active .form-select {
  border-bottom: 0;
  color: #ffbe40;
}

.nav-item-last {
  padding-right: 0 !important;
}

.tading-filter {
  cursor: pointer !important;
  transition: color 0.2s ease-in-out;
  line-height: 16px !important;
  padding-right: 12px !important;
  color: #b9b9ba;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  touch-action: manipulation;
  position: relative;
  outline: 0;
  padding: 0;
  margin: 0;
  border: none;
  color: inherit;
  font-size: inherit;
  font-size: 12px;
  background: transparent;
  text-decoration: none;
}

.tading-filter::before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  right: 0;
  top: 3px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 0;
  border-bottom: 4px solid;
  border-bottom-color: #dcdcdc;
  transition: border-bottom-color 0.2s ease-in-out;
}

.tading-filter::after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  right: 0;
  bottom: 3px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 0;
  border-top: 4px solid;
  border-top-color: #dcdcdc;
  transition: border-top-color 0.2s ease-in-out;
}

.last-price-filter {
  margin-right: 20px;
}

.trading-pair-accordian .accordion .accordion-item {
  border: 0;
  padding: 7px;
}

.trading-pair-accordian .accordion .accordion-item .accordion-button {
  padding: 0;
  background: transparent;
  border: 0;
  box-shadow: none;
  font-size: 13px;
  color: #ffc493;
  font-weight: 500;
}

.trading-pair-filter {
  margin-bottom: 20px;
}

.accordion-body {
  padding: 0;
}

.trading-pair-list {
  padding-top: 10px !important;
  height: 600px !important;
  overflow-y: scroll;
}

.trading-pair-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.trading-pair-list-left h5 {
  font-size: 14px;
  color: #666;
  margin-bottom: 0;
  font-weight: 400;
}

.trading-pair-list-left h5 span {
  font-weight: 400;
}

.trading-pair-list li:hover {
  background-color: #eee;
}

.pair-last-price {
  margin-right: 50px;
  display: inline-block;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-weight: 600;
}

.text-green {
  color: #00c186;
}

.text-red {
  color: #ff5761;
}

.pair-change {
  font-size: 14px;
  display: inline-block;
  font-weight: 600;
}

.order-card-heading h4 {
  font-size: 19px;
  color: #515151;
  margin: 0;
}

.order-card-header {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.order-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order-card-tabs .nav .nav-item .nav-link {
  padding: 0px 10px 0px 0px;
  background-color: transparent;
  opacity: 0.5;
}
.order-card-tabs .nav .nav-item .nav-link.active {
  opacity: 1;
}

.order-card-center-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.order-card-center-body span {
  font-size: 13px;
  font-weight: 600;
  color: #a1a1a1;
}

.order-card-pair-price {
  margin-top: 10px !important;
}

.order-card-pair-price li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.bg-rgba-red {
  background: #ff57792b;
  padding: 2px 15px;
  display: inline-block;
}
.order-card-pair-price li:hover {
  background: rgba(238, 86, 87, 0.1);
}

.order-card-pair-price li {
  padding: 0px 0px 0px 10px;
}

.order-card-pair-price .text-red {
  font-weight: 600;
}

.cuurent-pair-price {
  display: flex;
  justify-content: space-between;
  margin: 20px 10px !important;
}

.cuurent-pair-price li h4 {
  font-size: 18px;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 0;
}
.cuurent-pair-price li h4 span {
  color: #b9b9ba;
  font-size: 12px;
}

.order-card-pair-price.order-pair-buying li:hover {
  background: rgba(0, 193, 154, 0.1);
}

.order-card-pair-price.order-pair-buying li .bg-rgba-green {
  background-color: rgba(0, 193, 154, 0.1);
}

.page-title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0px;
}

.copy-ip-input .btn.btn-theme.btn-sm {
  position: absolute;
  top: 42px;
  right: 8px;
  background: transparent;
  border: 1px solid #878787;
  color: #878787 !important;
}


.copy-ip-input .btn.btn-theme.btn-sm svg {
  fill: #878787 !important;
  color: #878787 !important;
}

.input-holder select {
  width: 100%;
  height: 45px;
  color: #000;
  font-size: 18px;
  font-weight: 400;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
}

.input-holder textarea {
  min-height: 80px;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid rgba(65,91,115,.2);
  background-color: transparent;
}

.input-holder select:focus,
.input-holder textarea:focus {
  box-shadow: none;
  border: 1px solid #eee;
  outline: none;
  background-color: transparent;
}

.table-responsive.spot-table table tbody tr {
  vertical-align: middle;
}
.mobile-show {
  display: none;
}

/******* Responsive Navbar ******/

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: linear-gradient(
    127deg,
    rgba(1, 1, 1, 1) 0%,
    rgba(1, 1, 1, 1) 36%,
    rgba(64, 63, 63, 1) 70%,
    rgba(54, 54, 54, 1) 100%
  );
  overflow-x: hidden;
  transition: 0.5s;
  z-index: 500;
}

.sidenav-toggle {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: linear-gradient(
    127deg,
    rgba(1, 1, 1, 1) 0%,
    rgba(1, 1, 1, 1) 36%,
    rgba(64, 63, 63, 1) 70%,
    rgba(54, 54, 54, 1) 100%
  );
  overflow-x: hidden;
  transition: 0.5s;
  z-index: 500;
}

.mobile-nav-item {
  min-width: 320px;
  margin: 0 auto;
}

.side-nav-list a {
  padding: 15px 0px;
  text-decoration: none;
  color: #fff;
  transition: 0.3s;
  font-size: 20px;
}

.btn-outline-primary-mb {
  padding: 12px 15px !important;
  border-color: #fff;
}

.side-nav-list-menu.closebtn {
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 100%) {
  .sidenav {
    padding-top: 15px;
  }

  .side-nav-list a {
    font-size: 18px;
  }
}

.rigth-reserved-mobile p {
  color: #fff;
  float: right;
  padding-right: 40px;
}

.side-nav-list-menu i {
  color: #fff;
  font-size: 22px;
}

.side-nav-list-menu .side-nav-list li a {
  padding: 15px;
}

.side-nav-list-menu .side-nav-list li a:hover {
  text-decoration: underline;
}

.side-nav-list-menu .side-nav-list li:hover {
  transition: 0.5s;
  transform: translateY(-5px) scale(1.02);
}

.side-nav-list-menu .side-nav-list li {
  margin-right: 0;
  transition: 0.5s;
}

.side-nav-list-menu {
  justify-content: center;
  height: 45%;
}

.mobile-menu-list {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 10%;
}

.mobile-nav-reserved p {
  color: #fff;
  margin-bottom: 0;
}

.mobile-nav-item {
  min-width: 320px;
  margin: 0 auto;
}

.mobile-menu-icon svg {
  color: #000;
  font-size: 20px;
}

.mobile-nav-item .closebtn {
  color: #fff;
  font-size: 30px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: transparent;
  border: 0;
  position: absolute;
  top: 0;
  right: 20px;
}

.mobile-nav-reserved {
  min-width: 320px;
  margin: 0 auto;
}

.sidenav .closebtn {
  position: absolute;
}

.side-nav-list.mobile-list-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 110px;
}

.mobile-header {
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid #cdd9e5;
  box-shadow: 0px 1px 9px 0px rgb(0 0 0 / 20%);
}

.candle-time-selection {
  margin-left: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.candle-time {
  font-size: 12px;
  margin-right: 20px;
  cursor: pointer;
}

.candle-time:hover {
  color: #ffbe40;
}

.candle-time:focus {
  color: #ffbe40;
  box-shadow: none;
  outline: none;
}

.candle-time-selected {
  color: #ffbe40;
}

.candle-time > button {
  background-color: none;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 5px;
	background-color: #F5F5F5;
  height:5px;
}

::-webkit-scrollbar
{
	width: 5px;
  height:5px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 5px;
  height:5px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

.z-main-header {
  width: calc(100vw - 300px);
  position: fixed;
  top: 0;
  z-index: 11;
  filter: drop-shadow(0px 1px 3px rgba(0,0,0,0.1));
  background: #fff;
  padding: 15px;
}
.header-page-title h3 {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
}

.header-page-title{
  display: flex;
  align-items: center;
  gap: 17px;
}

.header-page-title .menu-toggle-btn{
  background: transparent;
  border: 0;
  outline: none;
  font-size: 20px;
}

.z-theme-wrapper {
  padding: 0 0 0 300px;
  transition: all .5s ease!important;
  min-height: 100vh;
}

.z-aside-structure {
  background-color: #fff;
  overflow: hidden;
}
.z-aside-holder .z-side-bar {
  z-index: 99;
  background-color: #fff;
}

.z-aside-structure .z-side-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  transition: all .5s ease-out;
}
@media (min-width: 992px){
.z-aside-structure .z-side-bar {
  left: 0;
}
}
.z-aside-structure .z-side-bar {
  left: 0%;
  transition: all .5s ease-out;
  border-right: 1px solid #e3e8ee;
}
.z-aside-holder .z-manu-holder {
  background-color: #fff;
  height: 67px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  overflow: hidden;
  filter: drop-shadow(0px 1px 3px rgba(0,0,0,0.1));
  background: #fff;
}
.z-aside-holder .z-manu-holder .menu-text {
  font-size: 15px;
  font-weight: 400;
}
.z-manu-holder a {
  display: inline-block;
  font-size: 20px !important;
}

.z-aside-holder .z-side-bar .z-drawer-menu {
  width: 100%;
  padding: 2.5rem 0 0px 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.z-manu-holder a {
  color: #000;
}

.z-drawer-menu li {
  margin-bottom: 4px;
  padding: 0px 10px;
}

.z-drawer-menu li a {
  padding: 17px 12px;
  font-size: 16px;
  font-weight: 300;
  border-radius: 6px;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 15px;
}

.z-drawer-menu li button {
  padding: 17px 12px;
  font-size: 16px;
  font-weight: 300;
  background-color: transparent;
  background: none;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
}

.z-drawer-menu li a.active,
.z-drawer-menu li button.active,
.z-drawer-menu li a.active:hover,
  .z-drawer-menu li button.active:hover{
  background: rgb(236, 236, 236);
  color: #000;
}
.z-drawer-menu li a svg, 
.z-drawer-menu li button svg {
  fill: #000 !important;
  height: 20px;
  width: 20px;
}

.z-drawer-menu li a:hover, 
.z-drawer-menu li button:hover {
  background-color:rgb(236, 236, 236);
}

.z-main-nav-right ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
  padding-right: 20px !important;
}

.z-main-nav-right ul li button {
  padding: 0;
  outline: none;
  margin: 0;
  background-color: transparent;
  border: 0;
  color: #0f4ba7;
  font-size: 23px;
}

.z-main-nav-right ul li a {
  color: #0f4ba7;
  font-size: 23px;
}

.last-nav-sidebar{
  position: absolute;
  bottom: 0;
  width: 100%;
}
.btn-blue{
  background-color: #0f4ba7;
  color: #fff;
  border: 1px solid #0f4ba7;
}

.page-inner-title{
  margin-bottom: 30px;
}

.page-inner-title h2{
  margin-bottom: 0;
  font-size: 23px;
  font-weight: 400;
}

.z-theme-wrapper .section-padding{
  background: #f7f9f9;
    min-height: 100vh;
    position: relative;
}

.ds-card{
  background: #fff;
  padding: 30px;
  border: 1px solid #eee;
  border-radius: 6px;
  position: relative;
}

.profile-card img{
  width: 80px;
  margin-bottom: 30px;
}


.profile-card ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
  border: 1px solid #dad8d8;
  padding: 6px 10px;
  border-radius: 5px;
}

.profile-card ul li:last-child{
  margin-bottom: 0;
}

.profile-card ul li p {
  margin-bottom: 0;
  font-size: 14px;
}

.profile-card ul li h4{
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
}

.update-info h3 {
  font-size: 19px;
  margin-bottom: 6px;
}

.update-info p {
  margin-bottom: 0px;
  font-size: 15px;
}

.affiliate-table thead tr{
  border: 1px solid #eee;
}

.affiliate-table thead tr th {
  border: 0;
  background: #0f4ba712;
  font-weight: 700;
  color: #222121;
  font-size: 15px;
}

.affiliate-table tbody tr td{
  padding: 13px 0.5rem;
  font-size: 15px;
  font-weight: 400;
  vertical-align: middle;
}

.badge-primary{
  background-color: #1f9553;
}

table .badge{
  font-weight: 400;
}

.badge-warning{
  background-color: #b99239;
}

.badge-danger{
  background-color: #a52b2b;
}

.subscription-card h3 {
  margin-bottom: 17px;
  font-size: 23px;
}

.subscription-card p {
  font-size: 18px;
  color: #3a3838;
}

.subscription-card p span {
  font-size: 26px;
    color: #0f4ba7;
    font-weight: 600;
}

.btn-deposit-holder {
  display: flex;
  align-items: center;
  gap: 20px;
}

.btn-deposit-holder .btn-deposit{
  background: transparent;
  border: 1px solid #0f4ba7;
  outline: none;
  padding: 3px 20px;
  font-size: 14px;
  color: #0f4ba7;
}

.token-sub-holder{
  gap: 10px;
}

.main-wrapper.z-aside-structure.aside-active .z-aside-structure .z-side-bar{
  left: -300%;
}

.main-wrapper.z-aside-structure.aside-active .z-theme-wrapper{
  padding-left: 0;
}


.main-wrapper.z-aside-structure.aside-active .z-main-header{
  width: 100%;
}

.login-wrapper{
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
}

.login-left{
  height: 100vh;
  width: 60%;
  padding: 80px 80px;
  background-color: #0f4ba7;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.login-right{
  display: flex;
  align-items: center;
  width: 40%;
  padding: 108px 100px 208px;
}

.login-logo a {
  font-size: 30px;
  color: #fff;
  display: inline-block;
}

.login-left-detail h2{
  font-size: 30px;
  color: #fff;
}

.login-left-detail p {
  margin: 0;
  color: #bfbcbc;
}

.login-form{
  width: 100%;
}

.z-main-nav-right .form-check .form-check-input{
  height: 20px;
}

.z-main-nav-right .form-check .form-check-input:focus{
  box-shadow: none;
  outline: none;
  border: 1px solid #eee;
}

.z-main-nav-right ul li a svg,
.z-main-nav-right ul li button svg {
  fill: #383838;
}

.toggle-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.toggle-slot {
  position: relative;
  margin-top: 5px;
  height: 30px;
  width: 70px;
  border: 1px solid #1a1c2236;
  border-radius: 10em;
  background-color: white;
  transition: background-color 250ms;
}

.toggle-checkbox:checked ~ .toggle-slot {
  background-color: #374151;
}

.toggle-button {
  transform: translate(2.75em, 0.28em);
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #ffeccf;
  box-shadow: inset 0px 0px 0px 0.75em #ffbb52;
  transition: background-color 250ms, border-color 250ms, transform 500ms cubic-bezier(.26,2,.46,.71);
}

.toggle-checkbox:checked ~ .toggle-slot .toggle-button {
  background-color: #485367;
  box-shadow: inset 0px 0px 0px 0.75em white;
  transform:translate(0.50em, 0.28em);
}

.sun-icon {
  position: absolute;
  height: 17px;
  width: 17px;
  color: #ffbb52;
  top: -18px;
  left: -25px;
}

.sun-icon-wrapper {
  position: absolute;
  height: 20px;
  width: 20px;
  opacity: 1;
  transform: translate(2em, 2em) rotate(15deg);
  transform-origin: 50% 50%;
  transition: opacity 150ms, transform 500ms cubic-bezier(.26,2,.46,.71);
}

.toggle-checkbox:checked ~ .toggle-slot .sun-icon-wrapper {
  opacity: 0;
  transform: translate(3em, 2em) rotate(0deg);
}

.moon-icon {
  position: absolute;
  height: 17px;
  width: 17px;
  color: white;
}

.moon-icon-wrapper {
  position: absolute;
  height: 20px;
  width: 20px;
  opacity: 0;
  transform: translate(11em, 2em) rotate(0deg);
  transform-origin: 50% 50%;
  transition: opacity 150ms, transform 500ms cubic-bezier(.26,2.5,.46,.71);
}

.toggle-checkbox:checked ~ .toggle-slot .moon-icon-wrapper {
  opacity: 1;
  transform: translate(2.8em, 0.29em) rotate(-15deg)
}
.ds-main-card{
  padding: 20px;
  background: #ececec;
  border: 1px solid #eee;
  border-radius: 4px;
  position: relative;
}
.ds-main-card-body{
  display: flex;
  align-items: center;
  gap: 30px;
}

.ds-main-left-icon span svg {
  fill: #000 !important;
  height: 30px;
  width: 30px;
}


.ds-main-left-icon span {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000038;
  border-radius: 5px;
}

.ds-main-right{

}

.ds-main-right span {
  font-size: 14px;
  display: block;
  font-weight: 300;
  margin-bottom: 5px;
  color: #272626;
}

.ds-main-right h3 {
  margin-bottom: 0;
  font-size: 25px;
  font-weight: 400;
}

.ds-main-card .ds-main-dropdown{
  position: absolute;
  top: 7px;
  right: 7px;
}

.ds-main-card .ds-main-dropdown .dropdown-toggle{
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
}

.ds-main-card .ds-main-dropdown .dropdown-toggle::after{
  display: none;
  content: "";
}

.ds-main-card .ds-main-dropdown .dropdown-toggle svg {
  fill: #000;
  height: 17px;
  width: 17px;
}

.ds-main-card .ds-main-dropdown .dropdown-toggle svg circle {
  fill: #3c3c3c !important;
}
.ds-card-title h3 {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 22px;
}

.ds-card-title{
  margin-bottom: 20px;
}


.theme-table {
  display: inherit;
  width: 100%;
}

 .theme-table .table thead tr{
  border: 1px solid #DCDFE8;
  background: transparent;
}
 .theme-table .table thead tr th{
  padding: 15px;
  font-size: 14px;
  background-color: #ececec;
  color: #fff;
  font-weight: 400;
}

.theme-table .table thead tr th{
  font-size: 14px;
  color: #415b73;
  font-weight: 800;
  white-space: nowrap;
}
.input-holder.role-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}
.input-holder input{
  width: 100%;
  outline: none;
  font-size: 16px;
  color: #415b73;
  height: 45px;
  border-radius: 5px;
  border: 1px solid rgba(65,91,115,.2);
  background-color: transparent;
}
 .theme-table .table thead tr th .table-head-th{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}
 .theme-table .table thead tr th .table-head-th span {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.table-head-th button svg {
  fill: #000 !important;
}

 .theme-table .table thead tr th .table-head-th button {
  padding: 0;
  margin: 0;
  outline: none;
  border: 0;
  background-color: transparent;
}
.input-holder.role-check input{
  height: 20px;
  width: 20px;
  padding: 0;
}

.token-img{
  display: flex;
  align-items: center;
  gap: 11px;
}

.token-img-detail span {
  font-size: 14px;
  font-weight: 500;
}

.token-img-detail p {
  margin-bottom: 0;
  margin-top: 2px;
  font-size: 14px;
  font-weight: 300;
}

.account-type-td ul{
  display: flex;
  align-items: center;
  gap: 10px;
}

.account-type-td ul li span {
  padding: 0 12px;
  background-color: #e6f6f5;
  height: 16px;
  font-size: 12px;
  font-weight: 400;
  color: #00a59a;
  border-radius: 14px;
}

.connect-wallet-td ul {
  display: flex;
  align-items: center;
  gap: 10px;
}

.connect-wallet-td ul li .connect-btn{
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  cursor: pointer;
  position: relative;
  outline: none;
  display: inline-grid;
  grid-auto-flow: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  user-select: none;
  touch-action: none;
  vertical-align: baseline;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;
  text-transform: inherit;
  font-weight: 600;
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  gap: 6px;
  height: 32px;
  max-height: 32px;
  min-width: 88px;
  padding: 6px 4px;
  border-radius: 4px;
  width: auto;
  color: rgb(255, 255, 255);
  background-color: rgb(11, 142, 135);
}
.connect-wallet-td ul li .connect-direct{
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  padding: 8px;
  min-width: 28px;
  display: inline-flex;
  margin-bottom: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 0;
  border-radius: 4px;
  box-sizing: border-box;
  letter-spacing: .24px;
  position: relative;
  transition-duration: .25s,.25s,.25s;
  transition-property: color,box-shadow,background-color;
  transition-timing-function: ease-in-out,ease-in-out,ease-in-out;
  flex-shrink: 0;
  color: #6b7b8b;
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #6b7b8b;
}

.subscription-main-tabs .nav.nav-pills{
  gap: 25px;
  border-bottom: 1px solid #eee;
  margin-bottom: 50px;
}

.subscription-main-tabs .nav.nav-pills .nav-item .nav-link{
  border: 2px solid transparent;
  color: #000;
  border-radius: 0px !important;
  font-size: 16px;
  font-weight: 400;
  padding: 0 0 10px 0;
  background: transparent;
  
}

.subscription-main-tabs .nav.nav-pills .nav-item .nav-link.active{
  color: #fff;
  background-color:transparent;
  border-bottom: 2px solid #0f4ba7;
  color: #0f4ba7;
}


.subscription-inner-tabs .nav.nav-pills{
  gap: 25px;
  border-bottom: 0 !important;
}

.subscription-inner-tabs .nav.nav-pills .nav-item .nav-link{
  border: 1px solid #0f4ba7;
  color: #0f4ba7;
  border-radius: 3px !important;
  padding: 8px 20px !important;
  font-size: 14px;
  font-weight: 400;
  background: #0f4ba708;
}

.subscription-inner-tabs .nav.nav-pills .nav-item .nav-link.active{
  color: #fff;
  background-color: #0f4ba7;
}

.subscription-inner-tabs .nav.nav-pills .nav-item .nav-link span {
  font-weight: 600;
}

.pricing-card-holder{
  position: relative;
  height: 100%;
  padding: 32px 32px 24px;
  background: rgb(255, 255, 255);
  border-radius: 20px;
  box-shadow: rgba(27, 28, 29, 0.04) 0px 4px 12px, rgba(27, 28, 29, 0.08) 0px 0px 2px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  place-content: stretch start;
  -webkit-box-pack: start;
}

.pricing-label{
  box-sizing: border-box;
  display: flex;
  flex-flow: row;
  -webkit-box-align: center;
  align-items: center;
  place-content: center start;
  -webkit-box-pack: start;
}

.pricing-label span {
  margin: 0px;
  padding: 0px;
  border: 0px;
  vertical-align: baseline;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 20px;
  font-weight: 600;
  color: rgb(112, 112, 112);
  fill: rgb(112, 112, 112);
}

.pricing-value{
  box-sizing: border-box;
  padding-top: 12px;
  margin-bottom: 30px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  place-content: flex-start start;
  -webkit-box-pack: start;
}

.pricing-value h3 {
  margin: 0px;
  padding: 0px;
  border: 0px;
  vertical-align: baseline;
  margin-bottom: 20px;
  font-size: 24px;
  letter-spacing: 0.3px;
  line-height: 32px;
  font-weight: 700;
  color: rgb(36, 36, 36);
  fill: rgb(36, 36, 36);
}

.pricing-value .btn-buy{
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  cursor: pointer;
  position: relative;
  outline: none;
  display: inline-grid;
  grid-auto-flow: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  user-select: none;
  touch-action: none;
  vertical-align: baseline;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-transform: inherit;
  font-weight: 600;
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  gap: 6px;
  min-height: 32px;
  min-width: 72px;
  padding: 0px 16px;
  border-radius: 8px;
  color: rgb(36, 36, 36);
  background-color: rgb(236, 236, 236);
  width: 150px;
}

.pricing-list-item{
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  place-content: stretch start;
  -webkit-box-pack: start;
}

.pricing-list-item p {
  margin: 0px;
  padding: 9px 0px;
  border: 0px;
  vertical-align: baseline;
  font-size: 15px;
  letter-spacing: 0.25px;
  line-height: 20px;
  font-weight: 400;
  color: rgb(36, 36, 36);
  fill: rgb(36, 36, 36);
}

.pricing-list-item p span {
  margin: 0px 4px 0px 0px;
  padding: 0px;
  border: 0px;
  vertical-align: baseline;
  font-size: 15px;
  letter-spacing: 0.25px;
  line-height: 20px;
  font-weight: 700;
  color: rgb(36, 36, 36);
  fill: rgb(36, 36, 36);
}

.pricing-card-popular{
  position: relative;
  height: 100%;
  padding: 32px 32px 24px;
  border-radius: 20px;
  box-shadow: rgba(27, 28, 29, 0.04) 0px 4px 12px, rgba(27, 28, 29, 0.08) 0px 0px 2px;
  background: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255)) padding-box padding-box, linear-gradient(90deg, rgb(20, 201, 193), rgb(28, 230, 221), rgb(12, 199, 228), rgb(30, 114, 210)) border-box border-box;
  border: 4px solid transparent;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  place-content: stretch start;
  -webkit-box-pack: start;
}

.pricing-card-popular .pricing-value .btn-buy{
  background-color: #0f4ba7;
  color: #fff;
}

.pricing-card-divider{
  box-sizing: border-box;
  display: block;
  flex-shrink: 0;
  width: 100%;
  height: 1px;
  margin-inline: auto;
  align-self: stretch;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pricing-card-divider::after{
  content: " ";
  display: flex;
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  background: rgb(224, 224, 224);
}

.pricing-more-list{
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  place-content: stretch start;
  -webkit-box-pack: start;
}

.pricing-more-list span{
  margin: 0px;
  padding: 12px 0px;
  border: 0px;
  vertical-align: baseline;
  font-size: 13px;
  letter-spacing: 0.25px;
  line-height: 20px;
  font-weight: 700;
  color: rgb(36, 36, 36);
  fill: rgb(36, 36, 36);
  white-space: nowrap;
}

.pricing-more-list span .only-bot{
  width: fit-content;
  vertical-align: baseline;
  box-sizing: border-box;
  padding: 2px 6px;
  margin-left: 8px;
  display: inline-flex;
  flex-flow: row;
  -webkit-box-align: center;
  align-items: center;
  place-content: center;
  -webkit-box-pack: center;
  border-radius: 4px;
  gap: 3px;
  background-color: rgb(234, 245, 245);
  color: rgb(19, 168, 161);
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.4px;
  text-transform: inherit;
  font-weight: 500;
}

.pricing-more-list p {
  margin: 0px;
  padding: 12px 0px;
  border: 0px;
  vertical-align: baseline;
  font-size: 13px;
  letter-spacing: 0.25px;
  line-height: 20px;
  font-weight: 400;
  color: rgb(36, 36, 36);
  fill: rgb(36, 36, 36);
}

.z-theme-wrapper .btn-outline-secondary{
    color: #6b7b8b;
    background-color: transparent;
    border: 1px solid #6b7b8b;
}

.z-theme-wrapper .btn-outline-secondary:hover{
  background-color: #6b7b8b;
  border: 1px solid #6b7b8b;
  color: #fff;
}

.refer-withdraw-btn{
  display: flex;
  align-items: center;
  gap: 10px;
}

.affiliate-card{
  padding: 0;
  height: 100%;
}

.affiliate-card .aff-card-head{
  padding: 15px 20px;
  border-bottom: 1px solid #d2d2d2; 
}

.affiliate-card .aff-card-head h4 {
  font-size: 16px;
  margin-bottom: 0;
  line-height: 20px;
  font-weight: 500;
  color: #2f4050;
}

.aff-card-body{
  padding: 15px 20px;
}

.aff-card-body h3 {
  margin: 0;
  font-size: 25px;
  font-weight: 500;
}

.aff-card-body p {
  font-size: 13px;
  color: #000;
  margin-bottom: 0;
  font-weight: 600;
}

.aff-card-body p span {
  color: #0f1bff;
}
.ds-card-title p {
  margin-bottom: 0;
  font-size: 15px;
}

.theme-table tbody tr td{
  padding: 13px 20px;
  font-size: 13px;
  vertical-align: middle;
  font-weight: 400;
  color: #000;
}

.z-theme-wrapper .btn{
  font-size: 15px;
  border-radius: 3px;
}

.page-title h2{
  font-size: 25px;
  margin-bottom: 0;
}

.z-theme-wrapper .btn-lg{
  font-size: 17px;
  padding: 11px 32px;
}

.connect-wallet-canvas{
  --bs-offcanvas-width:600px;
}

.select-acccount-alert{
  background-color: rgba(0,165,154,.0509803922);
  padding: 16px;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  justify-content: flex-start;
  border-radius: 4px;
  margin-bottom: 24px;
}

.select-alert-svg{
  display: flex;
  align-items: flex-start;
}

.select-alert-detail p {
  margin-bottom: 0;
  color: #6b7b8b;
  font-size: 13px;
}

.connect-wallet-canvas .offcanvas-body{
  padding: 50px;
}

.connect-wallet-canvas .offcanvas-body h4 {
  font-size: 22px;
  margin-bottom: 25px;
}

.select-account-holder li{
  margin-bottom: 22px;
}

.select-account-holder li:last-child{
  margin-bottom: 0;
}

.account-choose-holder{
  padding: 16px 22px;
  border: 1px solid #e8edf2;
  border-radius: 4px;
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-column-gap: 16px;
  outline: none;
}

.account-choose-holder .account-choose-svg{
  display: flex;
  align-items: center;
}

.ZEYul.zmoSw {
  color: #00a59a;
}

.account-choose-detail h4{
  color: #2f4050;
  font-weight: 600;
  font-size: 18px !important;
  line-height: 20px !important;
  margin-bottom: 7px !important;
}

.account-choose-detail p {
  margin-bottom: 0;
  color: #2f4050;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;  
}

.prt-main-card-head{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}


.prt-main-card-head-left h3 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}
.prt-main-card-head-left p{
  margin: 0;
  font-size: 14px;
  color: #000;
}

.prt-main-card-head-right button{
  background: transparent;
  border: 0;
  outline: none;
}

.prt-card-bar .progress-stacked{
  height: 8px;
}

.prt-card-bar .progress-stacked .prg-1{
  width: 25%;
}

.prt-card-bar .progress-stacked .prg-2{
  width: 20%;
}

.prt-card-bar .progress-stacked .prg-3{
  width: 30%;
}
.prt-card-bar .progress-stacked .prg-4{
  width: 25%;
}

.prt-card-bar .progress-stacked .bg-btc{
  background-color: #e9b64c;
  height: 8px;
}

.prt-card-bar .progress-stacked .bg-eth{
  background: #669be2;
  height: 8px;
}

.prt-card-bar .progress-stacked .bg-usdt{
  background: #30e0c7;
  height: 8px;
}

.prt-card-bar .progress-stacked .bg-bnb{
  background-color: #ff7007;
}

.token-prt{
  margin-right: 12px;
  display: inline-flex;
  margin-top: 8px;
  align-items: center;
  gap: 3px;
}

.token-value{
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 4px;
  display: inline-block;
}

.bg-token-eth{
  background-color: #669be2;
}
.bg-token-btc{
  background-color: #e9b64c;
}
.bg-token-usdt{
  background-color: #30e0c7;
}
.bg-token-bnb{
  background-color: #ff7007;
}

.token-prt:last-child{
  margin-right: 0;
}

.token-name,
.token-percent{
  color: #2f4050;
  font-size: 13px;
}

.prt-total-statistics{
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
}

.prt-total-statistics-left{
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.prt-total-statistics-left span {
  color: #6b7b8b;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.prt-total-statistics-right{
  display: flex;
  justify-content: flex-end;
  -moz-column-gap: 4px;
  column-gap: 4px;
}

.prt-total-statistics-blnc{
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  text-align: end;
}

.prt-total-statistics-blnc p {
  color: #2f4050;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 13px;
}

.prt-total-statistics-blnc span {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
}

.prt-total-statistics-blnc .plus-blnc {
  color: #00a59a;
}

.prt-total-statistics-blnc .minus-blnc{
  color: #ee7d8b;
}

.account-card-footer{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
}

.account-footer-left{
  display: flex;
  justify-content: stretch;
  flex-grow: 1;
  gap: 11px;
}

.account-footer-left .btn-theme{
  display: block;
  width: 100%;
}

.account-footer-left .btn-outline-theme{
  display: block;
  width: 100%;
}

.account-footer-right .dropdown button{
  outline: none;
  color: rgb(47, 64, 80);
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(107, 123, 139);
  padding: 9px;
}

.account-footer-right .dropdown button::after{
  content: "";
  display: none;
}

.account-type-td ul li .account-typ-low{
  background-color: #fff9ce;
  color: #a57700;
}

.account-type-td ul li .account-typ-mid{
  background-color: #cee3ff;
  color: #0062a5;
} 

.account-type-td ul li .account-typ-high{
  background-color: #ffcece;
  color: #a50036;
}

.trade-page-holder .page-title-trade h2 {
  margin-bottom: 0;
  font-size: 25px;
  font-weight: 700;
}

.connect-exchange-left{
  background: rgba(50, 114, 254, 0.05);
  border-radius: 8px;
  padding: 24px;
}

.connect-exchange-left ul li {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
}

.connect-exchange-left ul li:last-child{
  margin-bottom: 0;
}

.connect-exchange-left ul li p {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  margin: 0px;
  color: rgb(46, 61, 92);
  font-weight: 300;
}
.connect-exchange-left ul li p a{
  color: rgba(50, 114, 254, 0.8);
}

.connect-exchange-left h4 {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 11px;
}

.select-exchange-input label {
  font-size: 16px;
  color: #000;
  font-weight: 500;
  margin-bottom: 10px;
}

.select-exchange-input select {
  background-color: #eeeeee;
  border: 1px solid #e0dcdc;
  font-size: 14px;
  margin-bottom: 30px;
  height: 40px;
}

.select-exchange-input .btn{
  display: block;
  width: 100%;
  margin-bottom: 15px;
}

.select-exchange-input a {
  color: rgba(50, 114, 254, 0.8);
}

.connect-binance-btn-holder{
  text-align: center;
  border-bottom: 1px solid #eee;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.quick-connect-holder{
  display: flex;
  gap: 5px;
}

.quick-connect-holder p {
  margin-bottom: 0;
  font-size: 13px;
  color: #4f4c4c;
  line-height: 20px;
}

.quick-connect-holder span {
  font-weight: 700;
}


.plans {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.plans .plan input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.plans .plan {
  cursor: pointer;
  width: 48.5%;
}

.plans .plan .plan-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #e1e2e7;
  border-radius: 10px;
  -webkit-transition: -webkit-box-shadow 0.4s;
  transition: -webkit-box-shadow 0.4s;
  -o-transition: box-shadow 0.4s;
  transition: box-shadow 0.4s;
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
  position: relative;
}

.plans .plan .plan-content img {
  margin-right: 30px;
  height: 72px;
}

.plans .plan .plan-details span {
  margin-bottom: 10px;
  display: block;
  font-size: 15px;
  line-height: 12px;
  color: #252f42;
  font-weight: 600;
}

.container .title {
  font-size: 20px;
  font-weight: 500;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  color: #252f42;
  margin-bottom: 20px;
}

.plans .plan .plan-details p {
  color: #656668;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}

.plans .plan input[type="radio"]:checked + .plan-content:after {
  content: "";
  position: absolute;
  height: 8px;
  width: 8px;
  background: #216fe0;
  right: 20px;
  top: 20px;
  border-radius: 100%;
  border: 3px solid #fff;
  -webkit-box-shadow: 0px 0px 0px 2px #0066ff;
  box-shadow: 0px 0px 0px 2px #0066ff;
}

.plans .plan input[type="radio"]:checked + .plan-content {
  border: 2px solid #216ee0;
  background: #eaf1fe;
  -webkit-transition: ease-in 0.3s;
  -o-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
}

@media screen and (max-width: 991px) {
  .plans {
    margin: 0 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 40px;
  }

  .plans .plan {
    width: 100%;
  }

  .plan.complete-plan {
    margin-top: 20px;
  }

  .plans .plan .plan-content .plan-details {
    width: 70%;
    display: inline-block;
  }

  .plans .plan input[type="radio"]:checked + .plan-content:after {
    top: 45%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}

@media screen and (max-width: 767px) {
  .plans .plan .plan-content .plan-details {
    width: 60%;
    display: inline-block;
  }
}

@media screen and (max-width: 540px) {
  .plans .plan .plan-content img {
    margin-bottom: 20px;
    height: 56px;
    -webkit-transition: height 0.4s;
    -o-transition: height 0.4s;
    transition: height 0.4s;
  }

  .plans .plan input[type="radio"]:checked + .plan-content:after {
    top: 20px;
    right: 10px;
  }

  .plans .plan .plan-content .plan-details {
    width: 100%;
  }

  .plans .plan .plan-content {
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
}

/* inspiration */
.inspiration {
  font-size: 12px;
  margin-top: 50px;
  position: absolute;
  bottom: 10px;
  font-weight: 300;
}

.inspiration a {
  color: #666;
}
@media screen and (max-width: 767px) {
  /* inspiration */
  .inspiration {
    display: none;
  }
}

.billing-card-header h3 {
  font-size: 17px;
  margin-bottom: 5px;
}

.billing-card-header p {
  margin-bottom: 0;
  font-size: 15px;
  color: #000;
}

.billing-card-header{
  margin-bottom: 25px;
}

.billing-form-holder .input-holder{

}

.billing-form-holder .input-holder label {
  font-size: 14px;
  margin-bottom: 5px;
}

.billing-form-holder .input-holder input{
  height: 37px;
  font-size: 14px;
}

.billing-form-holder .input-holder select {
  height: 37px;
  font-weight: 400;
  font-size: 14px;
}

.billing-info-tabs-holder .subscription-inner-tabs .nav.nav-pills{
  margin-bottom: 20px;
}

.business-info-heading h3 {
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 20px;
}

.input-holder .btn:disabled{
  background: #0f4ba7;
    color: #fff;
    border: 1px solid #0f4ba7;
}

.term-affiliate-holder h3 {
  font-size: 17px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 500;
}

.term-affiliate-holder h3 a {
  color: #0f4ba7;
}

.term-affiliate-holder ul li {
  margin-bottom: 6px;
}

.term-affiliate-holder ul li:last-child {
  margin-bottom: 0;
}

.term-affiliate-holder ul li p{
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.term-affiliate-holder ul li p a {
  display: contents;
  color: #0f4ba7;
  font-weight: 500;
}

.term-affiliate-holder ul li p span {
  font-weight: 600;
}

.nav-pills.portfolio-mng-table{
  margin-bottom: 20px !important;
}

.help-card-body {
  text-align: center;
}
.help-card-body img {
  margin-bottom: 20px;
  width: 55px;
}

.help-card-body h3 {
  font-size: 30px;
  color: #000;
  font-weight: 600;
  margin-bottom: 8px;
}

.help-card-body p{
  margin-bottom: 0;
  font-size: 16px;
  color: #3e3c3c;
}

.support-card{
  min-height: 200px;
}

.ds-card-title.support-title{
  margin-bottom: 0 !important;
}

.ds-card-title.support-title h3{
  margin-bottom: 10px !important;
}

.ds-card-title.support-title p {
  font-size: 17px;
  margin-bottom: 0;
}


.input-holder {
  position: relative;
}
.upload-v-img.n-upload-img .upload-btn-wrapper {
  width: 100%;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-v-img.n-upload-img .upload-btn-wrapper .btn {
  min-width: 100%;
  width: 100%;
  margin-bottom: 0;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.upload-btn-wrapper .btn {
  border: 2px dashed #ccced0;
  color: #1a1f36;
  background-color: transparent;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 140px;
}

.upload-btn-wrapper .btn svg {
  font-size: 30px;
    margin-bottom: 11px;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.upload-btn-wrapper input[type=file] {
  width: 100%!important;
  height: 100%;
  cursor: pointer;
}

.setting-tabs-left{

}

.setting-tabs-left .nav-pills{

}

.setting-tabs-left .nav-pills .nav-link{
  text-align: left;
  padding: 20px 13px;
}
.tab-pill-detail{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tab-pill-left-detail {
  display: flex;
  align-items: center;
  gap: 16px;
}

.tab-pill-left-detail span {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ececec;
  color: #000;
  border-radius: 4px
}

.tab-pill-left-detail h4 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
}

.tab-pill-right-detail svg {
  color: #000;
}

.setting-tabs-left .nav-pills .nav-link.active{
  background: #ececec;
  border: 1px solid #e2e2e2;
}

.setting-tabs-left .nav-pills .nav-link.active .tab-pill-left-detail span{
  background: #a09f9f;
  color: #fff;
}

.setting-tabs-left .nav-pills .nav-link.active .tab-pill-right-detail svg {
  color: #252323;
}
.setting-right-main-card{
  background: #ececec;
  padding: 30px;
  border-radius: 5px;
}

.setting-right-main-card-header{
  margin-bottom: 30px;
}

.setting-right-main-card-header h3{
  font-size: 22px;
  margin-bottom: 9px;
  font-weight: 400;
}

.setting-right-main-card-header p{
  margin: 0;
  font-size: 16px;
  color: #373737;
  font-weight: 300;
}

.setting-right-main-card-body img {
  height: 120px;
  width: 120px;
  margin-bottom: 30px;
}

.kyc-tabs-holder .subscription-inner-tabs .nav-pills .nav-item .nav-link{
  min-width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
}

.kyc-tabs-holder .subscription-inner-tabs .nav-pills .nav-item .nav-link svg {
  font-size: 20px;
}

.kyc-tabs-holder .subscription-inner-tabs .nav-pills .nav-item .nav-link span {
  font-size: 15px;
  font-weight: 500;
}


.kyc-tab-detail h3 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 30px;
}

.kyc-tab-detail ul li {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 20px;
}

.kyc-tab-detail ul li:last-child{
  margin-bottom: 30px;
}

.kyc-tab-detail ul li span {
  font-size: 14px;
  color: #000;
  font-weight: 300;
}

.kyc-tab-detail p {
  margin-bottom: 0;
  font-size: 14px;
  color: #000;
  font-weight: 300;
}

.kyc-tabs-holder .subscription-inner-tabs .nav{
  margin-bottom: 40px !important;
}

.kyc-check-holder{
  height: 28px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0f4ba742;
  color: #0f4ba7;
  border-radius: 5px;
}

.kyc-tab-detail .upload-btn-wrapper .btn {
  font-size: 13px;
  font-weight: 500;
}

.delete-modal-holder .modal-body p{
  margin-bottom: 0;
  font-size: 16px;
  color: #000;
  font-weight: 400;
}

.modal .btn-secondary{
  background-color: #e2e8f0 !important;
  border: 1px solid #e2e8f0 !important;
  color: #1a202c;
}



.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown-header {
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(65,91,115,.2);
  background-color: transparent;
  position: relative;
}

.selected-exchange {
  display: flex;
  align-items: center;
}

.selected-exchange img {
  margin-right: 12px;
  height: 23px;
  width: 23px;
}

.selected-exchange p {
  margin-bottom: 0;
  color: #232020;
  font-size: 15px;
  font-weight: 500;
}

.dropdown-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-top: none;
  z-index: 1;
  height: 330px;
  overflow-y: scroll;
}

.dropdown-list li {
  cursor: pointer;
  padding: 12px 12px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cccccc42;
  grid-gap: 15px;
  gap: 17px;
}


.dropdown-list li:last-child {
  border-bottom: none;
}

.dropdown-list li p{
  margin-bottom: 0;
  color: #232020;
  font-size: 15px;
  font-weight: 500;
}

.dropdown-list li img {
  height: 30px;
  width: 30px;
}
.dropdown-header p {
  margin-bottom: 0;
}

.search-exchange-holder{
  padding: 20px;
  border-bottom: 1px solid #eee;
}

.search-exchange-holder .input-holder input{
  font-size: 15px;
  font-weight: 500;
}

.search-exchange-holder .input-holder svg {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 16px;
}

.custom-dropdown  .exchange-drop-icon{
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 17px;
}

.search-result-holder {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.search-result-holder .search-result{
  display: flex;
  align-items: center;
  padding: 13px;
  border: 1px solid #cacaca;
  margin-top: 20px;
  border-radius: 10px 0px 0px 10px;
  gap: 8px;
}

.search-result-holder .search-result img {
  height: 26px;
  width: 26px;
}

.search-result-holder .search-result p {
  margin: 0;
  font-size: 15px;
  color: #464646;
}

.search-result-holder .search-result span {
  font-weight: 600;
  font-size: 17px;
  color: #000;
}

.not-found-holder{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  text-align: center;
  justify-content: center;
  gap: 12px;
}

.not-found-holder p {
  margin: 0;
  color: #e53c3c;
  font-weight: 500;
  font-size: 16px;
}

.view-all-btn-holder {
  text-align: end;
}

.view-all-btn-holder a {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  color: #2741c1;
}

.support-exchange-tbl-title h3 {
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 400;
}

.table-pagination nav .pagination{
  justify-content: flex-end;
}

.table-pagination nav .pagination .page-link{
  color: #302f2f;
  font-size: 12px;
}

.trading-pair-list-right{
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trading-pair-list-left{
  width: 110px;
}

.page-bradcrumbs nav ol li a {
  display: inline-block;
}

.page-bradcrumbs nav ol{
  margin-bottom: 0;
}

.mm-alert-box {
  position: absolute;
  top: 99px;
  right: 40px;
}

.mm-alert-box .alert-info{
  
}


.started-amount-td span{
  font-size: 10px;
  font-weight: 600;
}

.wallet-address-card h3 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 40px;
}

.wallet-address-card .scan-wallet-img img{
  width: 250px;
}

.scan-wallet-img{
  text-align: center;
}

.wallet-or-holder{
  margin: 30px 0px;
  text-align: center;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  padding: 7px;
}

.wallet-or-holder span {
  font-size: 20px;
  color: #4d4ee0;
  font-weight: 600;
}

.note-wallet-holder p {
  margin: 0;
  font-size: 15px;
  font-weight: 300;
  color: #000;
}

.note-wallet-holder p span {
  color: red;
  font-weight: 500;
}

.bg-white-wallet{
  display: none;
}

.aff-convert-holder{
  display: flex;
  justify-content: space-between;
  margin-top: 9px;
  border-top: 1px solid #d8d8d8;
  padding-top: 9px;
}

.aff-covert-holder-left{

}

.aff-covert-holder-left h3 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 9px;
}

.aff-covert-holder-left p {
  font-size: 26px;
  font-weight: 400;
}

.withdraw-label{
  display: flex;
  align-items: center;
  gap: 4px;
}

.withdraw-label .wallet-network-name{
  margin: 0;
  font-size: 13px;
  color: #3631e4;
}

.withdraw-label .wallet-network-name span {
  color: #1d9059 !important;
}

.withdraw-label span{
  color: #3631e4;
  font-size: 13px;
}

.disclimar-text{
  margin-bottom: 30px;
  font-size: 14px;
  color: #000;
  font-weight: 400;
}

.subscription-inner-tabs.trade-api-tabs-holder .nav.nav-pills{
  gap: 8px;
}

.toggle-card{
  display: none;
}


.edit-api-show-btn-input .show-key-trade{
  position: absolute;
  top: 45px;
  right: 20px;
}

.aff-icon-head{
  display: flex;
  align-items: center;
  gap: 14px;
}