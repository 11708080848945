/*-----------------------------------------------------------------------------------
    Template Name   : Crypto Bot
    Developer       : Hunain Butt
    Description     : This is a CSS Template
    Version         : 1.0
-----------------------------------------------------------------------------------*/

/****** Theme Reset Style ***********************************************************
*********************************************************** Theme Reset Style ******/
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200;300;400;500;600;700;800;900&display=swap');

body{ font-size: 15px; color: #7e7e7e;  font-family: 'Lexend Deca', sans-serif !important; font-weight: 500;}
body h1, body h2, body h3, body h4, body h5, body h6{  margin: 0 0 13px;  font-weight: 700; font-family: 'Lexend Deca', sans-serif !important; color: #2c2b2b;}

div{ outline: none;}
p a{ color: #000; text-decoration: none; font-family: 'Lexend Deca', sans-serif !important;}
p a:hover{ text-decoration:underline; outline: none;}
p{ letter-spacing: normal; margin: 0 0 10px; color: #7e7e7e; line-height: 22px; font-size: 16px; font-weight: 400; font-family: 'Lexend Deca', sans-serif !important;}
a{ color: #000; text-decoration: none; outline: none !important; display: block; font-family: 'Lexend Deca', sans-serif !important;}
a:hover,
a:focus{ outline: none; text-decoration: none; color: #000;}
.btn:hover, .btn:focus, .btn.focus{ outline: none; text-decoration: none; color: #fff;}
/****** Image Style ******/
img{ max-width: 100%;}
/***** iframe ****/
iframe{ border: 0; width: 100%;}
/**** blockquote ****/
blockquote{ padding: 0; border: 0;}
/****** From Element Style ******/
label{ display: block; font-weight: 400; margin: 0;}
textarea{ border-radius: 0; resize: none;}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
input,
.form-control{ height: 45px; line-height: 50px; padding: 0 20px; border-radius: 4px; box-shadow: none;
	font-size: 13px;  position: relative; font-weight: 300;}
.form-control.border-0{ border: 0;}
.form-control.bg-wihet{ background: #fff;}
.form-control::-webkit-input-placeholder{ color: #999;} 
.form-control:-moz-placeholder{ color: #999;}
.form-control::-moz-placeholder{ color: #999;}
.form-control:-ms-input-placeholder{ color: #999;}
.form-control:focus::-webkit-input-placeholder{ opacity: 0;} 
.form-control:focus:-moz-placeholder{ opacity: 0;}
.form-control:focus::-moz-placeholder{ opacity: 0;}
.form-control:focus:-ms-input-placeholder{ opacity: 0;}
/****** From Element Style ******/

/****** Theme Reset Style ***********************************************************
*********************************************************** Theme Reset Style ******/

/****** Global Elements ***********************************************************
*********************************************************** Global Elements ******/
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
	display: block;
}
[tabindex="-1"]:focus:not(:focus-visible) {
	outline: 0 !important;
}

.wrapper ul{
	padding: 0;
	margin: 0;
}

.wrapper a {
	text-decoration: none;
}

.wrapper .container-fluid{
	width: 95%;
}
/**** row-no-gutter ***/
.row.no-gutters{ margin-right: 0; margin-left: 0;}
.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"]{ padding-right: 0; padding-left: 0;}

/******** Margin & Padding *******/
.mb-10{ margin-bottom: 10px;}
.mb-20{ margin-bottom: 20px;}
.mb-30{ margin-bottom: 30px;}
.mb-40{ margin-bottom: 40px;}
.mb-50{ margin-bottom: 50px;}
.mb-60{ margin-bottom: 60px;}
.mb-70{ margin-bottom: 70px;}
.mb-80{ margin-bottom: 80px;}
.mb-90{ margin-bottom: 90px;}
.mb-100{ margin-bottom: 100px;}
.mt-10{ margin-top: 10px;}
.mt-20{ margin-top: 20px;}
.mt-30{ margin-top: 30px;}
.mt-40{ margin-top: 40px;}
.mt-50{ margin-top: 50px;}
.mt-60{ margin-top: 60px;}
.mt-70{ margin-top: 70px;}
.mt-80{ margin-top: 80px;}
.mt-90{ margin-top: 90px;}
.mt-100{ margin-top: 100px;}
.pb-10{ padding-bottom: 10px;}
.pb-20{ padding-bottom: 20px;}
.pb-30{ padding-bottom: 30px;}
.pb-40{ padding-bottom: 40px;}
.pb-50{ padding-bottom: 50px;}
.pb-60{ padding-bottom: 60px;}
.pb-70{ padding-bottom: 70px;}
.pb-80{ padding-bottom: 80px;}
.pb-90{ padding-bottom: 90px;}
.pb-100{ padding-bottom: 100px;}
.pt-10{ padding-top: 10px;}
.pt-20{ padding-top: 20px;}
.pt-30{ padding-top: 30px;}
.pt-40{ padding-top: 40px;}
.pt-50{ padding-top: 50px;}
.pt-60{ padding-top: 60px;}
.pt-70{ padding-top: 70px;}
.pt-80{ padding-top: 80px;}
.pt-90{ padding-top: 90px;}
.pt-100{ padding-top: 100px;}
/**** Section-padding & section-margin ****/
.wrapper .section-padding{ padding: 100px 0;}
.section-margin{ margin: 100px 0;}
/***ul style***/
ul {margin: 0; padding: 0; list-style: none;}
/**** hr style ****/
hr { margin: 30px 0px; width: 80%; margin: 0 auto;}
/**** Truncate-Text ****/
.truncate-2{ overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
/****** Home Page ***********************************************************
*********************************************************** Home Page ******/
.header{
	padding: 20px 0px 20px 0px;
	background: #fff;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 99;
}

.wrapper.box-shadow .header.main-ds-header{
	box-shadow: rgba(104, 104, 104, 0.4) 0px 0px 5px;
}

.logo a{
	color: #0f4ba7 !important;
    display: inline-block !important;
	font-family: 'Lexend Deca', sans-serif !important;
	font-size: 25px !important;
}

.logo a img{
	width: 150px;
}

.main-menu{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 55px;
}

.main-header-left{
	display: flex;
    align-items: center;
    gap: 90px;
}

.header-btn{
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 20px;
}

.main-menu-list-item{
    font-size: 16px;
    font-weight: 300;
	font-family: 'Lexend Deca', sans-serif !important;
}

.main-menu-list.header-btn{
	margin-left: 60px;
}

.main-menu-list-item:last-child{
	margin-left: 0 !important;
}

.main-menu-list .submenu{
    position: absolute;
    border: 1px solid #eee;
    background-color: #fff;
    border-radius: 6px;
    width: 330px;
    margin-top: 17px;
    z-index: 99;
} 


.main-menu-list .submenu .submenu-list a {
	font-size: 15px;
	color: #454242;
	font-weight: 300;
	padding: 13px 20px;
	border-bottom: 1px solid #eeeeee69;
}

.wrapper .btn{
	padding: 8px 39px;
	display: inline-block;
	text-decoration: none;
	font-size: 16px;
	border-radius: 5px;
}

.wrapper .btn-fr-theme{
	background-color: #0f4ba7;
	border: 1px solid #0f4ba7;
	color: #fff;
}

.wrapper .btn-fr-outline-theme,
.wrapper .btn-fr-theme:hover{
	background-color: transparent;
	border: 1px solid #0f4ba7;
	color: #0f4ba7;
}

.main-menu li:last-child{
	margin-left: 0 !important;
}

.wrapper .btn-lg{
	padding: 13px 42px;
	font-size: 19px;
}

.banner-btn .btn{
	padding: 13px 45px;
    font-size: 20px;
}


.banner-caption-holder h2{
	font-size: 50px;
	color: #2c2b2b;
	margin-bottom: 29px;
	font-weight: 400;
}

.banner-caption-holder h2 span {
	background-image: url(../images/banner-coins/banner-heading-bg.svg);
	background-position: 50% 80%;
	background-repeat: no-repeat;
	background-size: contain;
}

.banner-caption-holder p{
	font-size: 24px;
	line-height: 35px;
	color: #000;
	font-weight: 200;
	margin-bottom: 40px;
}

.main-banner.section-padding{
	padding: 200px 0px 160px 0px;
}

.total-vol-holder{
	background-color: #0f4ba712;
	padding: 40px 0px !important;
}

.total-vol-card {
	text-align: center;
}

.total-vol-card h2 {
	color: #0f4ba7;
	font-size: 50px;
	font-weight: 400;
	margin-bottom: 20px;
}

.total-vol-card p {
	font-size: 18px;
	margin-bottom: 0;
	color: #222222;
	font-weight: 300;
}

.banner-vec-images .btc-img{
	position: absolute;
	top: 23%;
	width: 120px;
	left: 16%;
}

.banner-vec-images .bnb-img{
	position: absolute;
	top: 41%;
	width: 120px;
	left: 10%;
} 

.banner-vec-images .eth-img{
	position: absolute;
	bottom: 30%;
	width: 120px;
	left: 15%;
}

.banner-vec-images .solna-img{
	position: absolute;
	top: 23%;
	width: 120px;
	right: 16%;
}

.banner-vec-images .usdt-img{
	position: absolute;
	top: 41%;
	width: 120px;
	right: 10%;
}

.banner-vec-images .xrp-img{
	position: absolute;
	bottom: 30%;
	width: 120px;
	right: 15%;
}

.main-heading h2 {
	font-size: 45px;
	font-weight: 500;
	line-height: 65px;
	margin-bottom: 20px;
}

.main-heading p {
	margin-bottom: 30px;
	font-size: 19px;
	font-weight: 300;
	line-height: 31px;
	color: #1c1c1c;
}

.exchnage-trading-list{
	display: flex;
	align-items: center;
	gap: 30px;
	justify-content: flex-end;
}

.exchnage-trading-list.list-ex-one{
	margin-bottom: 35px;
}

.exchnage-trading-list.list-ex-two{
	justify-content: flex-start;
	margin-left: 50px;
	margin-bottom: 35px;
}

.exchnage-trading-list.list-ex-three{
	justify-content: flex-start;
}


.exchnage-trading-list a{
	z-index: 1;
	padding: 24px;
	text-decoration: none;
	border: 2px solid #000;
	border-radius: 10px;
	position: relative;
	background-color: #fff !important;
	transition: 0.3s;
	display: inline-block;
}

.exchnage-trading-list a img,
.exchnage-trading-list a svg{
	width: 100px;
}

.exchnage-trading-list a:hover{
	transition: 0.3s;
	transform: translateY(-5px);
}

.exchnage-trading-list li .bianace-echange-btn{
	background: #ffdf3a36;
}

.exchnage-trading-list li .okx-echange-btn{
	background: #E9ECF6;
}

.exchnage-trading-list li .coinbase-echange-btn{
	background: #3affb41f;
}
.exchnage-trading-list li .htx-echange-btn{
	background: #d9eeff;
}

.exchnage-trading-list li .bianace-us-echange-btn{
	background: #FFF3DC;
}

.exchnage-trading-list li .hitbtc-echange-btn{
	background: #E5F1FB;
}

.advance-feature-holder{
	background: #0f4ba712;
}

.feature-card{
	transition: 0.5s;
	text-align: center;
	padding: 40px;
	border: 1px solid #d4e0f68a;
	border-radius: 5px;
	min-height: 320px;
}

.feature-card:hover{
	transition: 0.5s;
	background-color: #fff;
}

.feature-card img {
	margin-bottom: 40px;
}

.feature-card h4{
	font-size: 22px;
	font-weight: 400;
	margin-bottom: 20px;
}

.feature-card p {
	font-weight: 300;
	margin-bottom: 0;
	font-size: 19px;
	color: #294e8d;
	line-height: 26px;
}

.btn-download-soon {
	display: flex;
	align-items: center;
	gap: 20px;
}

.btn-download-soon li a:hover{
	cursor: no-drop;
}

.text-green60 {
	color: #3091ff;
}

.mobile-app-soon{
	background-color: #1c1c1c;
	padding-bottom: 30px !important;
}

.mobile-app-soon .main-heading h2 {
	color: #fff;
	font-size: 40px;
	line-height: 56px;
}

.mobile-app-soon .main-heading p{
	color:#e8f9f4
}

.section-padding.advance-feature-holder{
	padding-bottom: 300px;
	position: relative;
}

.mobile-soon-img{
	margin-top: -320px;
	text-align: end;
}

.pricing-plan-section .subscription-inner-tabs .pricing-plan-ft-holder{
	display: flex;
	align-items: center;
	justify-content: center;
}

.pricing-plan-section .subscription-inner-tabs .pricing-plan-ft-holder .nav{
	display: inline-flex;
	background: #fff;
	padding: 7px 10px;
	border: 1px solid #a7a5a54d !important;
	border-radius: 7px;
}

.subscription-inner-tabs .pricing-plan-ft-holder .nav.nav-pills .nav-item .nav-link {
	background-color: transparent;
	border: 0;
	border-radius: 7px;
}

.subscription-inner-tabs .pricing-plan-ft-holder .nav.nav-pills .nav-item .nav-link.active{
	color: #fff !important;
	background-color: #0f4ba7 !important;
}

.subscription-inner-tabs .pricing-plan-ft-holder .nav.nav-pills {
	gap: 0px;
}

.pricing-plan-section{
	background-color: #eeeeee59;
}

.anullay-dic{
	background: #EAF0FF;
	width: fit-content;
	padding: 6px 16px;
	border-radius: 6px;
	font-size: 14px;
	color: #5890F9;
	margin: 14px 0 20px;
	max-width: 500px;
}

.anullay-dis-holder{
	margin-bottom: 50px;
}

.total-affiliate-benifits{
	text-align: end;
}

.total-affiliate-benifits h3 {
	font-size: 100px;
	font-weight: 500;
	color: #0f4ba7;
}

.total-affiliate-benifits h4 {
	font-weight: 400;
	font-size: 24px;
	margin-bottom: 20px;
}

.total-affiliate-benifits p {
	margin-bottom: 0;
	font-size: 26px;
	font-weight: 300;
	color: #000;
}

.section-padding.affiliate-section{
	background-color: #0f4ba712;
}

.secure-card{

}

.secure-card svg {
	height: 120px;
	width: 120px;
	margin-bottom: 20px;
}

.secure-card h3 {
	font-size: 22px;
	font-weight: 500;
	margin-bottom: 20px;
}

.secure-card p {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 300;
    color: #302f2f;
    line-height: 24px;
}

.footer-social-icon {
	display: flex;
	align-items: center;
}

.main-footer{
	padding: 80px 0px;
}

.footer-social-icon li a svg {
	height: 40px;
	width: 40px;
}

.footer-logo-holder a{
	display: inline-block;
}
.footer-logo-holder a img {
	width: 180px;
	margin-bottom: 30px;
}


.footer-logo-holder h4 {
	font-size: 17px;
	font-weight: 500;
}

.footer-logo-holder p {
	font-size: 15px;
	font-weight: 300;
	margin-bottom: 20px;
	color: #000;
}

.footer-menu-holder h4 {
	font-size: 19px;
	font-weight: 500;
	margin-bottom: 24px;
	color: #0D144E;
}


.footer-menu-holder ul li {
	margin-bottom: 12px;
}

.footer-menu-holder ul li:last-child{
	margin-bottom: 0px;
}

.footer-menu-holder ul li a{
	color: #0D144E;
	font-weight: 300;
	font-size: 15px;
	display: inline-block;
}

.footer-menu-holder ul li a:hover{
	 color: #0f4ba7;
}

.copyright-holder {
	text-align: center;
	background: #C9CFD447;
	padding: 20px 0px 15px;
}

.copyright-holder p {
	margin: 0;
	font-size: 14px;
	color: #767676;
}

.exchanges-mobile{
	display: none;
}
.main-policy-heading h3 {
	margin-bottom: 0px;	
	font-weight: 400;
	font-size: 32px;
}

.policy-holder{
	padding: 190px 0px 100px 0px;
}
.policy-content-detail{
	margin-bottom: 60px;
}

.policy-content-detail h4 {
	font-size: 21px;
	font-weight: 500;
	margin-bottom: 20px;
}

.policy-content-detail p {
	margin: 0;
	font-size: 17px;
	font-weight: 300;
	line-height: 30px;
	color: #595959;
}

.policy-content-detail p a {
	color: #0f4ba7;
	display: inline-block;
}

.policy-list{
	margin-top: 40px !important;
	list-style: auto;
	padding-left: 40px !important;
}

.policy-list li {
	margin-bottom: 14px;
}

.policy-list li:last-child{
	margin-bottom: 0;
} 

.policy-list li p span {
	font-weight: 500;
	color: #000;
}

.arb-exchnage-card{
	display: flex;
	align-items: center;
}

.arb-exc-left{
	margin-right: 30px;
}

.arb-exc-left img {
	width: 70px
}

.arb-exc-right h4{
	font-size: 23px;
	font-weight: 500;
	margin-bottom: 10px;
}

.arb-exc-right p {
	margin-bottom: 0;
	font-size: 17px;
	font-weight: 300;
	color: #000;
}

.arb-feature-section,
.available-auto-holder{
	background-color: #0f4ba712;
}

.arb-feature-card{
	text-align: center;
	background: #fff;
	border-radius: 5px;
	padding: 50px 30px;
	min-height: 280px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.arb-feature-card svg {
	height: 60px;
	width: 60px;
	margin-bottom: 30px;
}

.arb-feature-card h4 {
	font-size: 19px;
	font-weight: 500;
}

.arb-feature-card p {
	margin-bottom: 0;
	font-size: 15px;
	color: #515151;
	font-weight: 300;
}

.main-heading-points li {
	display: flex;
	align-items: center;
	gap: 11px;
	margin-bottom: 17px;
}

.main-heading-points li:last-child{
	margin-bottom: 0;
}

.main-heading-points li span svg {
	height: 27px;
	width: 27px;
}

.main-heading-points li p{
	margin-bottom: 0;
	font-size: 16px;
	margin-bottom: 0;
}

.portfolio-track-img img{
	width: 300px;
}

.modal-title{
	font-weight: 400 !important; 
}

.success-request-alert{
	margin-bottom: 25px;
}

.success-request-alert p {
	margin-bottom: 0;
	display: flex;
	align-items: center;
	gap: 7px;
	font-size: 19px;
}

.drag-drop-holder {
	padding-bottom: 0 !important;
}
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #0f4ba7;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader-container.loading {
  opacity: 1;
  visibility: visible;
}

/* Existing styles ... */

.loader svg {
  fill: #0D144E; /* Adjust the fill color */
  width: 100%;
  height: 100%;
}

.about-us-main-card{
	flex-direction: column;
	align-items: flex-start;
}

.about-us-main-card img {
	margin: 0;
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
}

.about-us-main-card h4 {
	font-size: 20px;
}

.about-us-main-card p {
	line-height: 26px;
}	


.start-how-list-holder{
	column-gap: 155px;
    grid-template-columns: repeat(auto-fit,182px);
	align-self: stretch;
	grid-row-gap: 20px;
    display: grid;
	justify-content: center;
    margin-bottom: 30px;
    row-gap: 20px;
}

.start__item{
	align-items: center;
    background-position: 11px 0;
    background-size: 162px 162px;
    min-height: 162px;
    padding-top: 90px;
	margin-bottom: 0;
    padding-left: 0;
	background-image: url(../images/about-us//list-start-bg.svg);
    background-position: 0 0;
    background-repeat: no-repeat;
    box-sizing: border-box;
	position: relative;
    display: flex;
}

.start__item:last-child::before{
	content: "";
	display: none !important;
}

.start__item:before {
	box-shadow: 30px 0 0 0 #e3ebfe, 44px 0 0 0 #e3ebfe, 75px 0 0 0 #e3ebfe;
    left: 190px;
    top: 80px;
    width: 15px;
	transform: none;
	position: absolute;
	content: "";
    height: 2px;
	background-color: #e3ebfe;
    border-radius: 1px;
}

.start__number{
	font-size: 80px;
    left: 21px;
    line-height: 109px;
    top: -20px;
	color: #3b82f6;
    position: absolute;
}

.start__info{
	align-items: center;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 10px rgba(37,99,235,.15);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
	padding: 8px;
	font-size: 14px;
    line-height: 20px;
    width: 200px;
	transition-property: transform,box-shadow,-webkit-transform;
	transform: translateY(0);
    transition-duration: .2s;
	border: none;
    color: #555;
    margin: 0;
    min-height: 54px;
    text-align: center;
}

.how-to-start-holder.section-padding{
	padding-top: 0;
}

.about-us-main-section{
	padding: 70px 0px !important;
}

.withdral-btn{
	margin-top: 10px !important;
    font-size: 12px !important;
    font-weight: 300 !important;
    padding: 4px 13px !important;
}
.withdral-btn:focus{
	color: #0D144E !important;
}

.start-trade-main-holder button{
	width: 140px !important;
	padding: 7px 10px !important;
}

.check-privacy-policy-input{
	margin-bottom: 30px;
}

.check-privacy-policy-input .form-check{
	display: flex;
    gap: 10px;
	padding-left: 0;

}

.check-privacy-policy-input .form-check input{
	padding: 0px;
    width: 25px;
    height: 25px;
}

.check-privacy-policy-input .form-check .form-check-label{
	margin: 0;
    display: flex;
    align-items: center;
    gap: 7px;
}

.check-privacy-policy-input .form-check .form-check-label a{
	color: #0f4ba7;
}
.ds-main-card .ds-main-dropdown{display: none;}

.count-badge {
  background-color: #0f4ba7;
  color: #fff;
  font-size: 12px;
  border-radius: 50%;
  padding: 2px 6px;
  margin-left: 10px;
}